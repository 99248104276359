import { Modal, Collapse } from "antd"
import { useQuill } from 'react-quilljs';
import Editor from 'components/editor';
import { useEffect, useState } from "react";
import useNotifications from 'hooks/useNotifications';
import useNotification from 'hooks/useNotification';

export default function ModalNotification({ isModalOpen, setIsModalOpen, editId = null }) {
    // const [notification, setNotification] = useState(null)
    const { find, response, isLoading, update } = useNotifications()
    const { contextHolder, openNotification } = useNotification()
    const [title, setTitle] = useState("")
    const theme = 'snow';
    const placeholder = 'Contenido de la notificación';

    const modules = {
        toolbar: [
            ['bold', 'italic', 'underline'],
            [{ 'header': 1 }, { 'header': 2 }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['link']
        ],
    };

    const { quill, quillRef } = useQuill({ theme, modules, placeholder });

    const fetchNotification = async () => {
        setTitle("")

        const data = await find({ id: editId }, true)
        // setNotification(data.data[0])
        setTitle(data.data[0].titulo)
        if (quill) {
            quill.clipboard.dangerouslyPasteHTML(data.data[0].mensaje);
        }
    }

    const handleClose = () => {
        setIsModalOpen(false)
    }

    useEffect(() => {
        if (editId) {
            fetchNotification()
        }
    }, [editId])

    const handleSave = async () => {
        const payload = {
            mensaje: quillRef.current.firstChild.innerHTML,
            titulo: title
        }

        await update(editId, payload, () => {
            openNotification('Cargado correctamente', 'success', 'Cargado')
            handleClose()
        })
    }

    return (
        <Modal
            // confirmLoading
            open={isModalOpen}
            onCancel={handleClose}
            onOk={handleSave}
            width="100%"
            okText="Guardar"
            cancelText="Cancelar"
            okButtonProps={{
                className: "bg-main"
            }}
        >
            <div className='flex flex-col items-start justify-center mt-4 w-full'>
                {contextHolder}

                <label className='text-sm font-semibold mb-1'>Título</label>
                <input value={title} onChange={(e) => setTitle(e.target.value)} type="text" className="border w-full rounded-md mb-4 px-2 py-1" />

                <label className='text-sm font-semibold mb-1'>Contenido</label>

                <div className="flex gap-4 w-full">
                    <Editor classname=" bg-white w-full" quillRef={quillRef} />
                    <div className="w-[40%]">

                        <Collapse defaultActiveKey={['0']} accordion>
                            <Collapse.Panel header="Referencias de institución">
                                <div className="flex flex-col gap-0">
                                    <span>Nombre : {`{`}i.nombre{`}`}</span>
                                    <span>Email : {`{`}i.email{`}`}</span>
                                </div>
                            </Collapse.Panel>

                            <Collapse.Panel header="Referencias de postulante">
                                <div className="flex flex-col gap-0">
                                    <span>Nombre : {`{`}o.nombre{`}`}</span>
                                    <span>Email : {`{`}o.email{`}`}</span>
                                </div>
                            </Collapse.Panel>

                            <Collapse.Panel header="Referencias de publicación">
                                <div className="flex flex-col gap-0">
                                    <span>Titulo : {`{`}p.titulo{`}`}</span>
                                    <span>Vencimiento : {`{`}p.vencimiento{`}`}</span>
                                </div>
                            </Collapse.Panel>

                            <Collapse.Panel header="Referencias de usuario">
                                <div className="flex flex-col gap-0">
                                    <span>Nombre : {`{`}u.nombre{`}`}</span>
                                    <span>Email : {`{`}u.email{`}`}</span>
                                    <span>Tipo : {`{`}u.tipo{`}`}</span>
                                </div>
                            </Collapse.Panel>

                            <Collapse.Panel header="Otras referencias">
                                <div className="flex flex-col gap-0">
                                    <span>Link recuperar contraseña : {`{`}a.recuperar{`}`}</span>
                                </div>
                            </Collapse.Panel>
                        </Collapse>

                    </div>
                </div>

                {/* {err.respuesta && <span className='text-sm text-red-600'>La respuesta es obligatoria</span>} */}

            </div>
        </Modal>
    )
}
