import { useQuill } from 'react-quilljs';
import Editor from 'components/editor';
import { useEffect, useState } from 'react';
import useTables from 'hooks/useTables';
import useNotification from 'hooks/useNotification';
import Loader from 'components/loader';
import { useForm } from "react-hook-form";
import { BiEdit, BiTrash } from "react-icons/bi";
import { Popconfirm } from 'antd';

export default function Pages() {
    const [isOpen, setIsOpen] = useState(false)
    const [editId, setEditId] = useState(null)

    const theme = 'snow';
    const placeholder = 'Contenido de la página';

    const modules = {
        toolbar: [
            ['bold', 'italic', 'underline'],
            [{ 'header': 1 }, { 'header': 2 }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['link']
        ],
    };

    const { quill, quillRef } = useQuill({ theme, modules, placeholder });


    const { contextHolder, openNotification } = useNotification()
    const { register, handleSubmit, reset, formState: { errors: err }, setValue } = useForm();
    const { response, isLoading, fetchData, store, destroy, update } = useTables("pages")


    const handleSave = async (data) => {

        data.contenido = quillRef.current.firstChild.innerHTML;

        data.header = data.header ? 1 : 0;
        data.footer = data.footer ? 1 : 0;

        if (editId) {
            await update(data, editId, () => {
                setIsOpen(false)
                setEditId(null)
                reset({ titulo: '', contenido: '' })
                openNotification('Editado correctamente', 'success', 'Cargado')
                if (quill) {
                    quill.clipboard.dangerouslyPasteHTML(data.contenido);
                }
            })
        } else {
            await store(data, () => {
                // console.log("PASO")
                reset({ titulo: '', contenido: '' })
                setIsOpen(false)
                fetchData()
                openNotification('Cargado correctamente', 'success', 'Cargado')
                if (quill) {
                    quill.clipboard.dangerouslyPasteHTML(data.terminos);
                }
            })
        }
    }

    const fetchPage = async () => {

        const data = await fetchData(editId)

        if (data) {
            setValue("titulo", data.titulo)
            setValue("header", data.header ? 1 : 0)
            setValue("footer", data.footer ? 1 : 0)

            if (quill) {
                quill.clipboard.dangerouslyPasteHTML(data.contenido);
            }
        }
    }

    useEffect(() => {
        if (editId) {
            fetchPage()
        } else {
            fetchData()
        }
    }, [editId])


    return <div>
        {contextHolder}

        <div className="mt-3 flex flex-col items-start">
            {!isOpen && <button onClick={() => setIsOpen(true)} className='bg-main px-5 py-2 rounded-lg text-sm font-semibold'>+ Agregar nueva página</button>}
        </div>

        <div className={isOpen ? '' : 'hidden'}>
            <div className='flex flex-col items-start justify-center'>
                <label className='text-sm font-semibold mb-1'>Título</label>
                <input
                    {...register("titulo", { required: true })}
                    className='border px-4 py-1 rounded-lg w-full'
                />
                {err.titulo && <span className='text-sm text-red-600'>El título es obligatorio</span>}
            </div>

            <div className='flex flex-col items-start justify-center mt-4'>
                <label className='text-sm font-semibold mb-1'>Respuesta</label>
                <Editor classname="h-[400px] bg-white" quillRef={quillRef} />

                {/* {err.respuesta && <span className='text-sm text-red-600'>La respuesta es obligatoria</span>} */}

            </div>

            <div className='flex flex-col gap-2 mt-14'>

                <div><input {...register("header")} type="checkbox" id="header" value="" /> <span htmlFor="header">Incluir en menú cabecera</span></div>
                <div><input {...register("footer")} type="checkbox" id="footer" value="" /> <span htmlFor="footer">Incluir en menú pie de página</span></div>

            </div>

            <div className='flex items-end justify-end gap-3 mt-4'>
                <button onClick={() => {
                    setIsOpen(false)
                    setEditId(null)
                    reset({ titulo: '', terminos: '' })
                }} className='bg-gray-50 text-sm px-6 font-bold py-2 rounded-lg hover:opacity-80'>Cancelar</button>
                <button onClick={handleSubmit(handleSave)} className='text-sm px-6 bg-main font-bold py-2 rounded-lg hover:opacity-80'>Guardar</button>
            </div>
        </div>

        <span className='font-semibold text-lg mt-5 block bg-white px-4 py-2 rounded-lg'>Páginas cargadas</span>

        {!isLoading && response?.length == 0 && <span className='block text-center mt-4 text-sm text-gray-500'>No hay preguntas frecuentes cargadas</span>}
        {isLoading && <Loader withWord={true} classname='mt-10' />}
        <div className='flex flex-col gap-2 mt-4'>
            {!isLoading && response?.length > 0 && response.map((item, idx) => {
                return <div key={idx} className='flex justify-between items-center py-2 px-4 bg-white rounded-lg gap-4'>
                    <div className='flex flex-col items-start py-2 px-4 bg-white gap-2'>
                        <span className='font-semibold'>{item.titulo}</span>
                        {/* <span className='text-sm'>{item.respuesta}</span> */}
                    </div>

                    <div className='flex flex-col items-start justify-center gap-2 px-4'>
                        <button
                            className="text-xs flex gap-2 text-gray-500 bg-transparent hover:outline-none active:outline-0"
                            onClick={() => { setEditId(item.id); setIsOpen(true) }}>
                            <BiEdit className="text-lg" />Editar</button>
                        <Popconfirm
                            title="Eliminar"
                            description="¿Está seguro que desea eliminar el registro?"
                            onConfirm={() => destroy(item.id)}
                            okText="Si, Eliminar"
                            cancelText="No"
                            okButtonProps={{
                                className: "bg-red-500 hover:bg-red-400"
                            }}
                        >
                            <button
                                className="text-xs flex gap-2 text-gray-500 bg-transparent hover:outline-none active:outline-0"
                            >
                                <BiTrash className="text-lg" />
                                Borrar
                            </button>
                        </Popconfirm>
                    </div>
                </div>
            })}
        </div>
        {/* <div className="flex flex-col items-start w-full"> */}
        {/* {isLoading && <div className='flex items-center justify-center w-full'><Loader /></div>} */}
        {/* <div className='w-full text-end mt-12 flex gap-4 items-center justify-end'>
                {errors && <span className="text-red-500 text-sm italic w-full text-end">{errors}</span>}
                <button onClick={() => handleSave()} className='text-sm px-6 bg-main font-bold py-2 rounded-lg hover:opacity-80'>Guardar</button>
            </div> */}
        {/* </div> */}
    </div>
}