import { routesNames } from "utils/Constants";


// Admin Imports
import MainDashboard from "views/admin/default";
import NFTMarketplace from "views/admin/marketplace";
import Profile from "views/admin/profile";
import DataTables from "views/admin/tables";
import RTLDefault from "views/rtl/default";
import Specialties from "views/admin/specialties/Specialties";

// Auth Imports
import SignIn from "views/auth/SignIn";

// Icon Imports
import {
  MdHome,
  MdOutlineShoppingCart,
  MdBarChart,
  MdPerson,
  MdSpaceDashboard,
  MdNotificationsNone,
  MdSettings,
  MdOutlineAccountTree,
} from "react-icons/md";

const routes = [
  {
    name: "Escritorio",
    layout: "/admin",
    path: "dashboard",
    icon: <MdHome className="h-6 w-6" />,
    component: <MainDashboard />,
  },
  {
    name: "Publicaciones",
    layout: "/admin",
    path: "publications",
    icon: <MdOutlineShoppingCart className="h-6 w-6" />,
    component: <MainDashboard />,

  },
  {
    name: "Pedidos",
    layout: "/admin",
    path: "marketplace",
    icon: <MdOutlineShoppingCart className="h-6 w-6" />,
    component: <MainDashboard />,

  },
  {
    name: "Pedidos - Planes",
    layout: "/admin",
    path: "plans",
    icon: <MdOutlineShoppingCart className="h-6 w-6" />,
    component: <MainDashboard />,

  },
  {
    name: "Contable",
    layout: "/admin",
    icon: <MdBarChart className="h-6 w-6" />,
    path: "contable",
    component: <MainDashboard />,
  },
  {
    name: "Usuarios",
    layout: "/admin",
    path: "users",
    icon: <MdPerson className="h-6 w-6" />,
    component: <MainDashboard />,
  },
  {
    name: "Especialidades",
    layout: "/admin",
    path: "specialties",
    icon: <MdOutlineAccountTree className="h-6 w-6" />,
    component: <MainDashboard />,
  },
  {
    name: "CMS",
    layout: "/admin",
    path: "cms",
    icon: <MdSpaceDashboard className="h-6 w-6" />,
    component: <NFTMarketplace />,
    parent: true
  },
  {
    name: "Preguntas frecuentes",
    layout: "/admin",
    path: "/admin/cms/faqs",
    icon: <MdSpaceDashboard className="h-6 w-6" />,
    component: <NFTMarketplace />,
    secondary: true,
  },
  {
    name: "Páginas",
    layout: "/admin",
    path: "/admin/cms/pages",
    icon: <MdSpaceDashboard className="h-6 w-6" />,
    component: <NFTMarketplace />,
    secondary: true,
  },
  {
    name: "Bloques y textos",
    layout: "/admin",
    path: "/admin/cms/blocks",
    icon: <MdSpaceDashboard className="h-6 w-6" />,
    component: <NFTMarketplace />,
    secondary: true,
  },
  // {
  //   name: "Términos y condiciones",
  //   layout: "/admin",
  //   path: "/admin/cms/terms",
  //   icon: <MdSpaceDashboard className="h-6 w-6" />,
  //   component: <NFTMarketplace />,
  //   secondary: true,
  // },
  {
    name: "Información de contacto",
    layout: "/admin",
    path: "/admin/cms/contact",
    icon: <MdSpaceDashboard className="h-6 w-6" />,
    component: <NFTMarketplace />,
    secondary: true,
  },
  {
    name: "Datos transferencia",
    layout: "/admin",
    path: "/admin/settings/data",
    icon: <MdSpaceDashboard className="h-6 w-6" />,
    component: <NFTMarketplace />,
    secondary: true,
  },
  {
    name: "Planes y precios",
    layout: "/admin",
    path: "/admin/cms/plans",
    icon: <MdSpaceDashboard className="h-6 w-6" />,
    component: <NFTMarketplace />,
    secondary: true,
  },
  {
    name: "Ajustes",
    layout: "/admin",
    path: "settings",
    icon: <MdSettings className="h-6 w-6" />,
    component: <MainDashboard />,
  },
  {
    name: "Ajustes Publicaciones",
    layout: "/admin",
    path: "/admin/settings/publications",
    icon: <MdSettings className="h-6 w-6" />,
    component: <MainDashboard />,
  },
  {
    name: "Ajustes Planes",
    layout: "/admin",
    path: "/admin/settings/plans",
    icon: <MdSettings className="h-6 w-6" />,
    component: <MainDashboard />,
  },
  {
    name: "Notificaciones Administrador",
    layout: "/admin",
    path: "notifications/administrador",
    icon: <MdNotificationsNone className="h-6 w-6" />,
    component: <MainDashboard />,
  },
  {
    name: "Notificaciones Institución",
    layout: "/admin",
    path: "notifications/institucion",
    icon: <MdNotificationsNone className="h-6 w-6" />,
    component: <MainDashboard />,
  },
  {
    name: "Notificaciones Postulante",
    layout: "/admin",
    path: "notifications/postulante",
    icon: <MdNotificationsNone className="h-6 w-6" />,
    component: <MainDashboard />,
  },
  {
    name: "Notificaciones Personalizadas",
    layout: "/admin",
    path: "notifications/custom",
    icon: <MdNotificationsNone className="h-6 w-6" />,
    component: <MainDashboard />,
  },
  {
    name: "Postulaciones",
    layout: "/admin",
    path: "postulations",
    icon: <MdNotificationsNone className="h-6 w-6" />,
    component: <MainDashboard />,
  }
];
export default routes;
