export const habilidades = [
    { label: 'Comunicación efectiva', value: '1' },
    { label: 'Diseño de materiales educativos', value: '2' },
    { label: 'Resolución de problemas educativos', value: '3' },
    { label: 'Gestión de proyectos educativos', value: '4' },
    { label: 'Investigación educativa', value: '5' },
    { label: 'Colaboración y trabajo en equipo', value: '6' },
    { label: 'Adaptabilidad a entornos educativos cambiantes', value: '7' },
    { label: 'Uso de tecnologías educativas', value: '8' },
    { label: 'Facilitación de talleres y capacitaciones', value: '9' },
    { label: 'Evaluación y análisis de programas educativos', value: '10' },
]

export const competencias = [
    { label: 'Diseño curricular adaptado a diferentes contextos', value: '1' },
    { label: 'Liderazgo pedagógico y educativo', value: '2' },
    { label: 'Análisis de necesidades y diseño de soluciones educativas', value: '3' },
    { label: 'Desarrollo de estrategias inclusivas', value: '4' },
    { label: 'Evaluación de impacto y mejora continua', value: '5' },
    { label: 'Gestión de la diversidad en entornos educativos', value: '6' },
    { label: 'Comunicación intercultural', value: '7' },
    { label: 'Resolución de conflictos en el ámbito educativo', value: '8' },
    { label: 'Uso estratégico de recursos educativos', value: '9' },
    { label: 'Empoderamiento de estudiantes y aprendizaje a lo largo de la vida', value: '10' },
]

export const aptitudes = [
    { label: 'Pensamiento crítico en contextos educativos', value: '1' },
    { label: 'Creatividad para diseñar enfoques pedagógicos innovadores', value: '2' },
    { label: 'Empatía hacia las necesidades de estudiantes y colegas', value: '3' },
    { label: 'Adaptabilidad a diferentes estilos de aprendizaje', value: '4' },
    { label: 'Habilidad para motivar y fomentar la participación', value: '5' },
    { label: 'Organización para administrar múltiples tareas educativas', value: '6' },
    { label: 'Habilidades de resolución de problemas', value: '7' },
    { label: 'Comunicación efectiva con diversos públicos', value: '8' },
    { label: 'Pensamiento estratégico para abordar desafíos educativos', value: '9' },
    { label: 'Curiosidad intelectual y aprendizaje continuo en educación', value: '10' },
]

export const tecnologias = [
    { label: 'Microsoft Windows', value: 'windows', group: 'Uso General' },
    { label: 'Internet (navegadores web)', value: 'internet', group: 'Uso General' },
    { label: 'Outlook (gestión de emails)', value: 'outlook', group: 'Uso General' },
    { label: 'Microsoft Word', value: 'word', group: 'Microsoft Office Suite' },
    { label: 'Microsoft Excel', value: 'excel', group: 'Microsoft Office Suite' },
    { label: 'Microsoft PowerPoint', value: 'powerpoint', group: 'Microsoft Office Suite' },
    { label: 'Adobe Photoshop', value: 'photoshop', group: 'Herramientas de Diseño Gráfico' },
    { label: 'Adobe Illustrator', value: 'illustrator', group: 'Herramientas de Diseño Gráfico' },
    { label: 'Canva', value: 'canva', group: 'Herramientas de Diseño Gráfico' },
    { label: 'Contify', value: 'contify', group: 'Herramientas de Gestión Contable' },
    { label: 'Tango Gestión', value: 'tango', group: 'Herramientas de Gestión Contable' },
    { label: 'Bejerman', value: 'bejerman', group: 'Herramientas de Gestión Contable' },
    { label: 'ContaPlus', value: 'contaplus', group: 'Herramientas de Gestión Contable' },
    { label: 'Libra Evolution', value: 'libra', group: 'Herramientas de Gestión Contable' },
]

export const motivos = [
    { name: 'Estoy sin empleo', value: 'sin-empleo' },
    { name: 'Busco un empleo mejor remunerado que mi empleo actual', value: 'mejor-empleo' },
    { name: 'No estoy contento/a con el ambiente laboral de mi empleo', value: 'no-contento' },
    { name: 'Me recibí y busco mi primer empleo', value: 'me-recibi' },
    { name: 'Otro (redactar)', value: 'otro' },
]

export const tipos = [
    { name: 'Empleo de tiempo completo', value: 'tiempo-completo' },
    { name: 'Empleo de medio tiempo', value: 'medio-tiempo' },
    { name: 'Suplencias o reemplazos', value: 'suplencia' },
    { name: 'Cualquiera de las anteriores', value: 'cualquiera' },
]

export const pretencionSalarial = [
    { name: 'Mayor a $50.000', value: '50' },
    { name: 'Mayor a $120.000', value: '120' },
    { name: 'Mayor a $175.000', value: '175' },
    { name: 'Mayor a $250.000', value: '250' },
]

export const languages = [
    { name: 'Castellano (español)', value: 'espanol' },
    { name: 'Inglés', value: 'ingles' },
    { name: 'Portugués', value: 'portuues' },
    { name: 'Francés', value: 'frances' },
    { name: 'Italiano', value: 'italiano' },
    { name: 'Alemán', value: 'aleman' },
    { name: 'Chino', value: 'chino' },
    { name: 'Árabe', value: 'arabe' },
    { name: 'Ruso', value: 'ruso' },
    { name: 'Coreano', value: 'coreano' },
    { name: 'Neerlandés (holandés)', value: 'holandes' },
    { name: 'Sueco', value: 'sueco' },
    { name: 'Griego', value: 'griego' },
    { name: 'Hebreo', value: 'hebreo' },
    { name: 'Polaco', value: 'polaco' },
    { name: 'Otro', value: 'otro' },
]

export const languagesLevels = [
    { name: 'Básico', value: 'basico' },
    { name: 'Intermedio', value: 'intermedio' },
    { name: 'Avanzado', value: 'avanzado' },
    { name: 'Biligue o nativo', value: 'nativo' },
]

export const states = [
    { name: 'Ciudad Autónoma de Buenos Aires', value: 'caba' },
    { name: 'Buenos Aires', value: 'buenos-aires' },
    { name: 'Catamarca', value: 'catamarca' },
    { name: 'Chaco', value: 'chaco' },
    { name: 'Chubut', value: 'chubut' },
    { name: 'Córdoba', value: 'cordoba' },
    { name: 'Corrientes', value: 'corrientes' },
    { name: 'Entre Ríos', value: 'entre-rios' },
    { name: 'Formosa', value: 'formosa' },
    { name: 'Jujuy', value: 'jujuy' },
    { name: 'La Pampa', value: 'la-pampa' },
    { name: 'La Rioja', value: 'la-rioja' },
    { name: 'Mendoza', value: 'mendoza' },
    { name: 'Misiones', value: 'misiones' },
    { name: 'Neuquén', value: 'neuquen' },
    { name: 'Río Negro', value: 'rio-negro' },
    { name: 'Salta', value: 'salta' },
    { name: 'San Juan', value: 'san-juan' },
    { name: 'San Luis', value: 'san-luis' },
    { name: 'Santa Cruz', value: 'santa-cruz' },
    { name: 'Santa Fe', value: 'santa-fe' },
    { name: 'Santiago del Estero', value: 'santiago' },
    { name: 'Tierra del Fuego, Antártida e Islas del Atlántico Sur', value: 'tierra-del-fuego' },
    { name: 'Tucumán', value: 'tucuman' },
]

export const genres = [
    { name: 'Femenino', value: 'F' },
    { name: 'Masculino', value: 'M' },
    { name: 'Otro', value: 'O' },
    { name: 'Prefiero no decirlo', value: 'X' },
]

export const estadosCiviles = [
    { name: 'Soltera/o', value: 'soltera' },
    { name: 'Casada/o', value: 'casada' },
    { name: 'Viuda/o', value: 'viuda' },
    { name: 'Divorciada/o', value: 'divorciada' },
]

export const condicionesIva = [
    { name: 'Responsable Inscripto', value: 'ri' },
    { name: 'Iva Exento', value: 'e' },
    { name: 'Responsable monotributo', value: 'rm' },
    { name: 'Consumidor final', value: 'cf' },
]

export const cantidadEmpleados = [
    { name: '0 - 10', value: '10' },
    { name: '10 - 50', value: '50' },
    { name: '50 - 100', value: '100' },
    { name: '100 - 500', value: '500' },
]

export const turnos = [
    { name: 'Mañana', value: 'm' },
    { name: 'Tarde', value: 't' },
    { name: 'Ninguno', value: 'n' },
]

export const formacion = [
    { 'name': 'Secundario', value: 'secundaria' },
    { 'name': 'Terciario', value: 'terciaria' },
    { 'name': 'Universitario', value: 'universitario' },
    { 'name': 'Master / Posgrado / Especialización', value: 'master' },
    { 'name': 'Curso', value: 'curso' }
]

export const progresoFormacion = [
    { name: 'Completo', value: 'completo' },
    { name: 'Inconcluso', value: 'inconcluso' },
    { name: 'Cursando actualmente', value: 'en-curso' },
]

export const tipoDeFormacion = [
    { 'name': 'Curso', value: 'curso' },
    { 'name': 'Especialización', value: 'especializacion' },
]

export const tipoDePuestos = [
    { 'name': 'Puesto permanente', value: 'puesto-permanente' },
    { 'name': 'Suplencia', value: 'suplencia' },
    { 'name': 'Período de duración pactado', value: 'periodo-de-duracion-pactado' },
    { 'name': 'Pasantía', value: 'pasantia' }
]

export const motivoFinalizacion = [
    { 'name': 'Fin de período pactado', value: 'fin-periodo-pactado' },
    { 'name': 'Interrupción por licencia por enfermedad', value: 'enfermedad' },
    { 'name': 'Interrupción por licencia por maternidad', value: 'maternidad' },
    { 'name': 'Renuncia', value: 'renuncia' },
    { 'name': 'Despido', value: 'despido' },
    { 'name': 'Otros', value: 'otros' },
]

export const niveles = [
    { 'name': 'Inicial', value: 'inicial' },
    { 'name': 'Primario', value: 'primario' },
    { 'name': 'Secundario', value: 'secundario' },
    { 'name': 'Terciario', value: 'terciario' },
    { 'name': 'Universitario', value: 'universitario' },
    { 'name': 'Sin nivel específico', value: 'sin-nivel' },
]

export const jornadas = [
    { 'name': 'Jornada simple', value: 'simple' },
    { 'name': 'Jornada completa', value: 'completa' },
]

export const posiciones = [
    { 'name': 'Suplencia', value: 'suplencia' },
    { 'name': 'Titular', value: 'titular' },
    { 'name': 'A convenir', value: 'a-convenir' }
]


export const tipoContratacion = [
    { 'name': 'Temporal/Suplencia', value: 'temporal' },
    { 'name': 'Cargo permanente', value: 'permanente' },
    { 'name': 'Pasantía', value: 'pasantia' },
    { 'name': 'A convenir', value: 'convenir' },
]