import { Table, Tag } from "antd";
import usePublications from "hooks/usePublications";
import { useEffect, useState } from "react";
import { getFullDay, getFullMonth, getNameFromObject } from "utils/Utils";
import { useNavigate } from "react-router-dom";
import { routesNames } from "utils/Constants";
import { niveles } from "utils/Tables";
import { Link } from "react-router-dom";
import { Input } from "antd";
import { BiSearchAlt } from "react-icons/bi";
import ExportExcel from "components/excel/ExportExcel";
import dayjs from 'dayjs';
import { getTree } from "services/Especialidades";


const PublicationTable = ({ user = null }) => {
    let navigate = useNavigate();
    const [datasource, setDatasource] = useState([]);
    const [value, setValue] = useState(null)
    const { isLoading, response, fetchData } = usePublications()
    const [filters, setFilters] = useState([]);
    const [dataExcel, setDataExcel] = useState([]);

    const getState = (item) => {
        const vencimiento = new Date(item.vencimiento);
        const fecha = new Date();
        let vencida = false;

        if (fecha > vencimiento) {
            vencida = true
        }

        if (vencida) {
            return "Vencida"
        }

        if (item.activa == 0) {
            return "Desactivada"
        }

        if (item.estado) {
            return item.estado.estado.nombre
        } else {
            return ""
        }
    }

    const dataExport = (data, especialidades) => {
        const exportData = data.map((item) => {
            return {
                Estado: getState(item),
                Nombre: item.nombre,
                Cuenta: item.cuenta?.nombre,
                Referencia: item.referencia,
                Especialidad: especialidades?.tree?.tag[item.especialidad_id],
                Liderazgo: item.liderazgo ? "Si" : "No",
                Visibilidad: item.privada ? "Confidencial" : "Pública",
                Creada: dayjs(item.created_at).format('DD/MM/YYYY'),
                Vencimiento: dayjs(item.vencimiento).format('DD/MM/YYYY'),
                Nivel: getNameFromObject(niveles, item.nivel),
                Jornada: item.jornada,
                Posicion: item.posicion,
                Turno: item.turno == 'n' ? 'Noche' : item.turno == 'm' ? 'Mañana' : 'Tarde',
                Visitas: item.visitas_count
            }
        })

        setDataExcel(exportData)
    }

    // console.log(response)
    const columns = [
        {
            title: 'Nombre',
            dataIndex: 'nombre',
            key: 'nombre',
            render: (text) => {
                if (text.length > 50) {
                    return <span className="text-sm" dangerouslySetInnerHTML={{ __html: text.slice(0, 50) }}></span>
                } else {
                    return <span className="text-sm" dangerouslySetInnerHTML={{ __html: text }}></span>
                }
            },
        },
        {
            title: 'Cuenta',
            dataIndex: 'cuenta',
            key: 'cuenta',
            render: (text, record) => {
                return <Link to={`${routesNames.USER}/${record.cuenta.user_id}`} className="text-blue-700">{record.cuenta?.nombre}</Link>;
            },
            filters: filters,
            onFilter: (value, record) => record.cuenta?.nombre === value
        },
        {
            title: 'Visibilidad',
            dataIndex: 'privada',
            key: 'privada',
            render: (text, record) => {
                if (record.privada == 0) {
                    return <Tag className="bg-[#34d399] text-white" >Pública</Tag>
                } else {
                    return <Tag className="bg-cancelado text-white" >Confidencial</Tag>
                }
            },

            filters: [
                {
                    text: 'Pública',
                    value: 0,
                },
                {
                    text: 'Confidencial',
                    value: 1,
                },
            ],
            onFilter: (value, record) => record?.privada == value
        },
        {
            title: 'Fecha',
            dataIndex: 'created_at',
            render: (text) => {
                const date = new Date(text);
                return `${getFullDay(date, true)}/${getFullMonth(date)}/${date.getFullYear()}`
            },
            sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at)
        },
        {
            title: 'Vencimiento',
            dataIndex: 'vencimiento',
            render: (text) => {
                const date = new Date(text);
                return `${getFullDay(date, true)}/${getFullMonth(date)}/${date.getFullYear()}`
            },
            sorter: (a, b) => new Date(a.vencimiento) - new Date(b.vencimiento)
        },
        {
            title: 'Nivel',
            dataIndex: 'nivel',
            render: (text) => {
                return getNameFromObject(niveles, text)
            },
            filters: niveles.map((n) => {
                return {
                    text: n.name,
                    value: n.value
                }
            }),

            onFilter: (value, record) => record.nivel == value
        },
        // {
        //     title: 'Medio de Pago',
        //     dataIndex: 'mp',
        //     key: 'mp',
        //     render: (text, record) => {
        //         return record.facturacion?.medio_pago?.nombre
        //     }
        // },
        {
            title: 'Estado',
            dataIndex: 'estado',
            render: (text, record) => {
                const vencimiento = new Date(record.vencimiento);
                const fecha = new Date();
                let vencida = false;

                if (fecha > vencimiento) {
                    vencida = true
                }

                if (vencida) {
                    return <Tag className="bg-cancelado text-white" >Vencida</Tag>
                }

                if (record.activa == 0) {
                    return <Tag className="bg-cancelado text-white" >Desactivada</Tag>
                }

                if (record.estado) {
                    return <Tag color={`${record.estado.estado.color}`}>{record.estado.estado.nombre}</Tag>
                } else {
                    return ""
                }
            },

        },
        {
            title: 'Detalle',
            dataIndex: 'detalle',
            render: (text, record) => {
                return <button onClick={() => navigate(`${routesNames.PUBLICATION}/${record.id}`)} className="bg-main text-sm w-full rounded-xl">Ver</button>
            }
        },
    ];

    const fetchPublications = async () => {
        // console.log(user)
        const tree = await getTree()
        const data = await fetchData(null, { userId: user }, true)

        setDatasource(data?.data)
        dataExport(data?.data, tree)
    }

    useEffect(() => {
        fetchPublications()
    }, [])

    useEffect(() => {
        if (datasource.length > 0) {
            const uniqueAccounts = Array.from(new Set(datasource.map((item) => item.cuenta?.nombre)))
                .filter((name) => name !== undefined) // Excluye undefined si hay cuentas sin nombre
                .map((name) => ({
                    text: name,
                    value: name
                }));

            setFilters(uniqueAccounts);
        }
    }, [datasource]);

    return (
        <>
            {/* <div className="bg-white rounded-lg w-full px-2 mb-4 py-2 flex gap-4"> */}
            <Input
                allowClear
                placeholder="Buscar por Cuenta,Nombre de publicación o nivel"
                prefix={<BiSearchAlt className="text-xl" />}
                value={value}
                size="small"
                className="p-3 w-full rounded-lg mb-2"
                onChange={e => {
                    const currValue = e.target.value;
                    setValue(currValue);

                    // console.log(response)
                    const filteredData = response.filter(entry =>
                        entry.cuenta?.nombre?.toLowerCase().includes(currValue.toLowerCase()) || entry.nombre.toLowerCase().includes(currValue.toLowerCase()) || entry.nivel.toLowerCase().includes(currValue.toLowerCase())

                    );
                    setDatasource(filteredData);
                }}
            />
            {/* </div> */}

            <div className="flex flex-row justify-between items-center mb-3">
                <span className="block text-sm font-semibold text-start my-1 me-5">
                    {datasource?.length} publicaciones encontradas
                </span>
                <ExportExcel data={dataExcel} fileName={"Publicaciones_" + new Date().toLocaleDateString()} />
            </div>

            <Table
                loading={isLoading}
                dataSource={datasource}
                columns={columns}
                className="w-full"
                rowKey={(record) => record.id}
                size="small"
                pagination={
                    {
                        pageSize: 20,
                        showSizeChanger: false
                    }
                }
                locale={{ emptyText: "No hay resultados" }}
                onChange={(pagination, filters, sorter, extra) => {
                    dataExport(extra.currentDataSource)
                }}
            />
        </>
    )
};

export default PublicationTable;
