import { Descriptions, Popconfirm, Tag, DatePicker, Input } from 'antd';
import Loader from 'components/loader';
import { SITE_URL } from "config/config";
import useUsers from 'hooks/useUsers';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { condicionesIva, states } from 'utils/Tables';
import { getNameFromObject } from 'utils/Utils';
import PublicationTable from '../publications/components/PublicationTable';
import { DeleteOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom';
import { routesNames } from 'utils/Constants';
import { getCurriculumPDF } from "services/Usuarios"
import useNotification from 'hooks/useNotification';
import { BASE_API } from "config/config"
import { FaFilePdf } from "react-icons/fa";
import { useForm } from "react-hook-form";
import Button from 'components/Button';
import LogList from 'components/adminLogs/LogList';

const { TextArea } = Input
const dayjs = require('dayjs')


export default function ViewUser() {
    let { id } = useParams();
    let navigation = useNavigate()
    const { contextHolder, openNotification } = useNotification()

    const { isLoading, response, fetchData, verifyAccount, changePlan, deleteUser } = useUsers()
    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm()
    const [selectedPlan, setSelectedPlan] = useState(null)

    const disabledDate = (current) => {
        return current && current < dayjs().startOf('day');
    };

    const fetchPdf = async (userId) => {
        const data = await getCurriculumPDF(userId)

        if (data?.uuid) {
            window.open(`${BASE_API}cv/${data.uuid}.pdf`, "_blank", "noreferrer");
        } else {
            openNotification('El curriculum se encuentra incompleto', 'success', 'Atención!')
        }
    }

    const onChange = (date, dateString) => {
        console.log(date, dateString);
    };

    // console.log(response)
    useEffect(() => {
        fetchData(id)
    }, [])

    if (isLoading) {
        return <Loader />
    }


    return (
        <div className='w-full bg-white rounded-lg p-8'>
            {contextHolder}
            <div className='flex justify-between items-center'>
                <div className='flex items-center'>
                    {response.cuenta?.verificado == 0 ?
                        <Tag color="red">Cuenta no verificada</Tag>
                        :
                        <Tag color="green">Cuenta verificada</Tag>
                    }

                    {response.cuenta?.activo == 0 ?
                        <Tag color="red">Cuenta inactiva</Tag>
                        :
                        <Tag color="green">Cuenta activa</Tag>
                    }

                    {response.cuenta?.rechazado == 1 &&
                        <Tag color="red">Cuenta rechazada</Tag>
                    }

                    {response.cuenta?.suspendido == 1 &&
                        <Tag color="red">Cuenta suspendida</Tag>
                    }


                </div>

                <div className='flex gap-2 mt-2 items-start'>
                    <a target='_blank' rel='noopener noreferrer' href={`${SITE_URL}loginAsUser?uuid=${response?.uuid}&type=${response?.type}`} className='rounded-md px-4 py-1 text-sm bg-aprobado text-white'>Ingresar como usuario</a>

                    {response?.type == 'P' && <a href='#' onClick={() => fetchPdf(response?.id)} className='bg-red-500 text-sm text-white px-4 rounded-md py-1 flex items-center'><FaFilePdf className='text-white' /> Descargar CV</a>}

                    {response.cuenta?.verificado == 0 &&
                        <Popconfirm
                            title="Verificar cuenta"
                            description="¿Está seguro que desea confirmar la verificación de la cuenta?"
                            onConfirm={async () => {
                                await verifyAccount(response.id, { verificado: 1, tipo: response.type })
                                window.location.reload()
                            }}
                            // onCancel={() => { }}
                            okText="Si"
                            cancelText="No"
                            okButtonProps={
                                { className: "bg-blue-500" }
                            }
                        >
                            <button className='rounded-md px-4 py-1 text-sm bg-aprobado text-white'>Verificar cuenta</button>
                        </Popconfirm>
                    }

                    {response.cuenta?.verificado == 0 && response.cuenta?.activo == 0 &&
                        <Popconfirm
                            title="Verificar y activar cuenta"
                            description="¿Está seguro que desea confirmar la verificación y la activación de la cuenta?"
                            onConfirm={async () => {
                                await verifyAccount(response.id, { verificado: 1, activo: 1, tipo: response.type })
                                window.location.reload()
                            }}
                            // onCancel={() => { }}
                            okText="Si"
                            cancelText="No"
                            okButtonProps={
                                { className: "bg-blue-500" }
                            }
                        >
                            <button className='rounded-md px-4 py-1 text-sm bg-aprobado text-white'>Verificar y activar cuenta</button>
                        </Popconfirm>
                    }

                    {response.cuenta?.verificado == 1 && response.cuenta?.activo == 0 &&
                        <Popconfirm
                            title="Activar cuenta"
                            description="¿Está seguro que desea confirmar la activación de la cuenta?"
                            onConfirm={async () => {
                                await verifyAccount(response.id, { activo: 1, tipo: response.type })
                                window.location.reload()
                            }}
                            // onCancel={() => { }}
                            okText="Si"
                            cancelText="No"
                            okButtonProps={
                                { className: "bg-blue-500" }
                            }
                        >
                            <button className='rounded-md px-4 py-1 text-sm bg-aprobado text-white'>Activar cuenta</button>
                        </Popconfirm>
                    }



                    {response.cuenta?.rechazado == 0 &&
                        <Popconfirm
                            title="Rechazar cuenta"
                            description="¿Está seguro que desea rechazar la cuenta?"
                            onConfirm={async () => {
                                await verifyAccount(response.id, { rechazado: 1, tipo: response.type })
                                window.location.reload()
                            }}
                            // onCancel={() => { }}
                            okText="Si"
                            cancelText="No"
                            okButtonProps={
                                { className: "bg-blue-500" }
                            }
                        >
                            <button className='rounded-md px-4 py-1 text-sm bg-cancelado text-white'>Rechazar cuenta</button>
                        </Popconfirm>
                    }

                    {response.cuenta?.suspendido == 0 &&
                        <Popconfirm
                            title="Suspender cuenta"
                            description="¿Está seguro que desea suspender la cuenta?"
                            onConfirm={async () => {
                                await verifyAccount(response.id, { suspendido: 1, tipo: response.type })
                                window.location.reload()
                            }}
                            // onCancel={() => { }}
                            okText="Si"
                            cancelText="No"
                            okButtonProps={
                                { className: "bg-blue-500" }
                            }
                        >
                            <button className='rounded-md px-4 py-1 text-sm bg-pendiente text-white'>Suspender cuenta</button>
                        </Popconfirm>
                    }


                    {(!response?.cuenta || response?.cuenta?.length == 0 || response?.cuenta?.rechazado == 1 || response?.cuenta?.suspendido == 1) &&
                        <Popconfirm
                            icon={
                                <DeleteOutlined
                                    style={{
                                        color: 'red',
                                    }}
                                />
                            }
                            title="Eliminar cuenta"
                            description="¿Está seguro que desea eliminar la cuenta? Se borrarán todos los datos y publicaciones, y no se podrá recuperar!"
                            onConfirm={async () => {
                                await deleteUser(response.id)
                                navigation(routesNames.USERS)
                            }}
                            // onCancel={() => { }}
                            okText="ELIMINAR"
                            cancelText="No"
                            okButtonProps={
                                { className: "bg-blue-500" }
                            }
                        >
                            <button className='rounded-md px-4 py-1 text-sm bg-red-600 text-white'>Eliminar cuenta</button>
                        </Popconfirm>
                    }

                    {response?.type == 'P' && <a target='_blank' rel='noopener noreferrer' href={`${SITE_URL}cv/${response.uuid}`} className='bg-main text-sm text-white px-4 rounded-md py-1'>Ver curriculum</a>}
                </div>
            </div>

            <div>
                {response.type == "I" &&
                    <div>

                        <Descriptions column={3} bordered={true} size="small" className='mt-4' items={[
                            {
                                key: '1',
                                label: 'Nombre',
                                children: `${response?.cuenta.nombre}`,
                            },
                            {
                                key: '2',
                                label: 'CUIT',
                                children: `${response?.cuenta.cuit}`,
                            },
                            {
                                key: '3',
                                label: 'Domicilio',
                                children: `${response?.cuenta.domicilio}`,
                            },
                            {
                                key: '4',
                                label: 'Ubicación',
                                children: `${response?.cuenta.localidad}, ${getNameFromObject(states, response?.cuenta.provincia)} (CP ${response?.cuenta.cp})`,
                            },
                            {
                                key: '1',
                                label: 'Responsable nombre',
                                children: `${response?.cuenta.responsable_nombre}`,
                            },
                            {
                                key: '2',
                                label: 'Responsable teléfono',
                                children: `${response?.cuenta.responsable_telefono}`,
                            },
                            {
                                key: '3',
                                label: 'Responsable email',
                                children: `${response?.cuenta.responsable_email}`,
                            },
                            {
                                key: '4',
                                label: 'Responsable DNI',
                                children: `${response?.cuenta.responsable_documento}`,
                            },
                        ]} />

                        {/* <Descriptions column={4} bordered={true} size="small" items={[
                            {
                                key: '1',
                                label: 'Responsable nombre',
                                children: `${response?.cuenta.responsable_nombre}`,
                            },
                            {
                                key: '2',
                                label: 'Responsable teléfono',
                                children: `${response?.cuenta.responsable_telefono}`,
                            },
                            {
                                key: '3',
                                label: 'Responsable email',
                                children: `${response?.cuenta.responsable_email}`,
                            },
                            {
                                key: '4',
                                label: 'Responsable DNI',
                                children: `${response?.cuenta.responsable_documento}`,
                            },
                        ]} /> */}

                        <div className='flex justify-between items-start'>
                            <div className='flex flex-col gap-2 my-4 w-full'>
                                <span className='text-base font-semibold'>Ubicaciones</span>
                                {response.ubicaciones.length > 0 && response.ubicaciones?.map((item, idx) => (
                                    <div key={idx}>
                                        <span className='text-sm' >{item.domicilio}, {item.localidad} ({item.codigo_postal}), {getNameFromObject(states, item.provincia)}</span>
                                    </div>
                                ))}
                            </div>

                            <div className='flex flex-col gap-2 my-4 w-full'>
                                <span className='text-base font-semibold'>Datos de facturación</span>
                                {response.ubicaciones.length > 0 && response.ubicaciones?.map((item, idx) => (
                                    <div key={idx} className='flex flex-col'>
                                        <span className='text-sm font-normal' >{item.razon_social} - {item.cuit} - {getNameFromObject(condicionesIva, item.condicion_iva)}</span>
                                        <span className='text-sm' >{item.calle} {item.numero}, {item.localidad}, {getNameFromObject(states, item.provincia)}</span>

                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className='flex justify-between items-start'>
                            <div className='flex flex-col my-8 w-full'>
                                <span className='text-base font-semibold'>Responsables</span>
                                {response.responsables.length > 0 && response.responsables?.map((item, idx) => (
                                    <div key={idx}>
                                        <span className='text-sm' >Nombre: {item.name}, Cargo: {item.position}, Correo: {item.email}</span>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className='flex gap-4 mt-4'>
                            <div className='flex flex-col w-1/4 gap-4 me-20'>

                                <div className='flex flex-row gap-4 items-center'>
                                    <span className=' block text-base font-semibold'>Plan : <span className='font-normal'>{!response.plan ? "Estandar" : response.plan?.detalle?.nombre}</span></span>
                                    <span className=' block text-base font-semibold'>Vence el: <span className='font-normal'>18/09/2024</span></span>

                                </div>

                                <div className='flex gap-2 items-center'>
                                    <select onChange={(e) => {
                                        setSelectedPlan(e.target.value)
                                    }} className='border pl-2 py-1 rounded-lg w-[200px]'>
                                        <option>Seleccionar</option>
                                        {response.planes.map((plan, idx) => (
                                            <option key={idx} value={plan.id}>{plan.nombre}</option>
                                        ))}
                                    </select>

                                    <Popconfirm
                                        title="Cambiar plan"
                                        description="¿Está seguro que desea cambiar el plan del usuario?"
                                        onConfirm={async () => {
                                            if (selectedPlan) {
                                                await changePlan(response.id, selectedPlan)
                                                window.location.reload()

                                            }
                                        }}
                                        // onCancel={() => { }}
                                        okText="Si"
                                        cancelText="No"
                                        okButtonProps={
                                            { className: "bg-blue-500" }
                                        }
                                    >
                                        <button className='rounded-md px-4 py-1 text-sm bg-aprobado text-white'>Cambiar plan</button>
                                    </Popconfirm>
                                </div>

                                <div className='flex flex-col gap-2'>
                                    <span className='text-base mt-4 font-semibold'>Vencimiento del plan gold</span>
                                    <DatePicker
                                        onChange={onChange}
                                        className='w-full h-10'
                                        format={'DD/MM/YYYY'}
                                        defaultValue={dayjs()}
                                        disabledDate={disabledDate}
                                    />

                                    <TextArea
                                        name="observaciones"
                                        placeholder='Observaciones'
                                        rows={4}
                                        className='w-full'
                                    />
                                    <button className='rounded-md px-4 py-1 text-sm bg-aprobado text-white'>Guardar datos</button>
                                </div>
                            </div>

                            <div className='flex-grow'>
                                <LogList className='w-full' />
                            </div>
                        </div>



                        <span className='mt-6 block mb-2 text-base font-semibold'>Publicaciones</span>
                        <PublicationTable user={response.id} />
                    </div>
                }

                {response.type == "P" &&
                    <div>

                        <Descriptions column={4} bordered={true} size="small" className='mt-4' items={[
                            {
                                key: '1',
                                label: 'Nombre',
                                children: `${response?.name}`,
                            },
                            {
                                key: '2',
                                label: 'DNI',
                                children: `${response?.cuenta.dni}`,
                            },
                            {
                                key: '3',
                                label: 'Nacimiento',
                                children: `${response?.cuenta.nacimiento}`,
                            },
                            {
                                key: '4',
                                label: 'Sexo',
                                children: `${response.cuenta.sexo == 'M' ? "Masculino" : "Femenino"}`,
                            },
                            {
                                key: '5',
                                label: 'Teléfono',
                                children: `${response.cuenta?.telefono}`,
                            },
                            {
                                key: '6',
                                label: 'Ubicación',
                                children: `${response.cuenta.localidad}, ${getNameFromObject(states, response?.cuenta.provincia)}`,
                            },
                        ]} />

                        <span className='mt-6 block mb-2 text-base font-semibold'>Postulaciones</span>
                        <div className='flex flex-col gap-1'>
                            <span className='text-sm'>Postulaciones a publicaciones: {response.postulaciones}</span>
                            <span className='text-sm'>Postulaciones instantaneas: {response.postulaciones_instantaneas}</span>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}
