import { HttpGet, HttpPost, HttpPostImage, HttpPut, HttpDelete } from "./Http"


export const getAll = async (table) => {
    try {

        const data = await HttpGet(`admin/${table}`, false)
        return data;
    } catch (error) {
        return error
    }
}

export const get = async (id, table) => {
    try {
        const data = await HttpGet(`admin/${table}/${id}`, false)
        return data;
    } catch (error) {
        return error
    }
}

export const storeTable = async (payload, table, editId = null) => {
    try {
        let data;
        if (editId) {
            data = await HttpPut(`admin/${table}/${editId}`, JSON.stringify(payload))
        } else {
            data = await HttpPost(`admin/${table}`, JSON.stringify(payload))
        }
        return data;
    } catch (error) {
        return error
    }
}

export const deleteTable = async (id, table) => {
    try {
        const data = await HttpDelete(`admin/${table}/${id}`)
        return data;
    } catch (error) {
        return error
    }
}