import React, { useEffect } from 'react'
import useRequests from "hooks/useRequests";
import { useParams } from 'react-router-dom';
import Loader from 'components/loader';
import { getNameFromObject, formatCurNumber } from "utils/Utils";

import { routesNames, estadoPublicacion } from "utils/Constants"
import { jornadas, states, tipoContratacion, turnos, condicionesIva } from "utils/Tables";
import { Tag, Popconfirm } from "antd";
import { useNavigate } from 'react-router-dom';
const PUBLIC_URI = process.env.REACT_APP_PUBLIC_URI_IMAGES;

export default function Plan() {
    let navigate = useNavigate()
    const { isLoading, response, fetchData, changeStatus } = useRequests()
    let { id } = useParams();

    useEffect(() => {
        fetchData(id)
    }, [])

    const changeStatusRequest = async (id, payload) => {

        const bodyPayload = {
            ...payload,
            type: 'U'
        }
        const data = await changeStatus(id, bodyPayload)
        window.location.reload()
        // navigate(routesNames.REQUESTS)
    }

    if (isLoading) {
        return (
            <div className="mt-10 flex items-center justify-center">
                <Loader />
            </div>
        )
    }


    // const changeStatusRequest = async (publicationId, statusId) => {

    //     const data = await changeStatus({ publicationId: publicationId, status: statusId })
    //     // window.location.reload()
    //     navigate(routesNames.REQUESTS)
    // }

    if (!isLoading && response) {
        return (
            <div className="mt-3 flex items-start justify-between min-h-screen gap-2">

                <div className='border rounded-lg w-full h-full p-4 bg-white'>
                    <div className=' border flex items-center gap-3 px-4 w-full py-3 rounded-lg'>
                        <div className='w-[80px] sm:w-[100px]'>
                            <img className="object-contain w-full h-full z-0" src={`${PUBLIC_URI}uploads/${response?.cuenta?.logo_url}`} />
                        </div>

                        <div className='flex flex-col gap-1 items-start'>
                            <span className='text-xl sm:text-xl font-bold'>{response?.cuenta?.nombre}</span>
                            {response?.cuenta?.slogan && <span className='font-semibold text-md' >{response.cuenta.slogan}</span>}
                            {response?.cuenta?.resena && <span className='text-gray-500 text-xs text-start'>{response.cuenta.resena}</span>}

                        </div>
                    </div>

                    <div className='flex gap-2'>
                        <div className='mt-4 flex flex-col gap-2 text-sm'>
                            <span className='text-lg mb-2 font-semibold'>Detalle operación</span>
                            {/* <span>Datos solicitante: {response.cuenta?.user?.email}</span> */}
                            <span className='font-semibold'>Responsable:</span>
                            <div className='flex flex-col gap-0'>
                                <span className=''>Nombre: {response.cuenta?.responsable_nombre}</span>
                                <span className=''>Email: {response.cuenta?.responsable_email}</span>
                                <span className=''>Teléfono: {response.cuenta?.responsable_telefono}</span>
                                <span className=''>DNI: {response.cuenta?.responsable_documento}</span>
                            </div>

                            <span className='font-semibold mt-4'>Datos facturación:</span>
                            <div className='flex flex-col gap-0'>
                                <span className=''>{response?.data?.razon_social}</span>
                                <span className=''>{response?.data?.cuit}</span>
                                <span className=''>{getNameFromObject(condicionesIva, response?.data?.condicion_iva)}</span>
                                <span className=''>{response?.data?.calle} {response?.data?.numero}, {response?.data?.localidad}, {response?.data?.provincia}</span>
                            </div>

                            <span className='font-semibold mt-4'>Pack:</span>
                            <div className='flex flex-col gap-0'>
                                <span className=''>Contratar plan -  ${formatCurNumber(response?.importe)}</span>
                            </div>

                            <span className='my-4'><span className='font-semibold'>Medio de pago:</span> {response?.medio_pago?.nombre}</span>


                            {/* {response?.pago_confirmado == 1 && <Tag color='green'>Pago confirmado</Tag>} */}
                            <span className='font-semibold text-2xl'>Total ${formatCurNumber(response?.importe)}</span>

                            {/* {response?.pago_confirmado == 0 &&
                                <div className='mt-2'>
                                    <Popconfirm
                                        title="Confirmar pago"
                                        description="¿Está seguro que desea confirmar la recepción del pago?"
                                        onConfirm={async () => {
                                            await confirmPay(response.user_id)
                                            window.location.reload()
                                        }}
                                        okText="Si"
                                        cancelText="No"
                                        okButtonProps={
                                            { className: "bg-blue-500" }
                                        }
                                    >
                                        <button className='rounded-md px-4 py-1 text-sm bg-blue-500'>Confirmar pago y aprobar</button>
                                    </Popconfirm>
                                </div>
                            } */}


                            <div className='flex flex-col gap-2 mt-2'>

                                <span className='font-semibold text-base'>Pago</span>
                                {response?.pago_rechazado == 1 && <Tag color='red' className='text-center'>Pago rechazado</Tag>}
                                {response?.pago_confirmado == 1 && <Tag color='green'>Pago confirmado</Tag>}
                                {response?.facturado == 1 && <Tag color='blue'>Facturado</Tag>}

                                {response?.pago_confirmado == 0 && response?.facturado == 0 &&
                                    <Popconfirm
                                        title="Confirmar y facturar pago"
                                        description="¿Está seguro que desea confirmar la recepción del pago y facturar el pedido?"
                                        onConfirm={async () => await changeStatusRequest(response.id, { pago_confirmado: 1, facturado: 1 })}
                                        okText="Si"
                                        cancelText="No"
                                        okButtonProps={
                                            { className: "bg-blue-500" }
                                        }
                                    >
                                        <button className='rounded-md px-4 py-1 text-sm bg-blue-600 text-white'>Confirmar y facturar</button>
                                    </Popconfirm>

                                }

                                {response?.pago_confirmado == 1 && response?.facturado == 0 &&
                                    <Popconfirm
                                        title="Facturar"
                                        description="¿Está seguro que desea facturar el pedido?"
                                        onConfirm={async () => await changeStatusRequest(response.id, { facturado: 1 })}
                                        okText="Si"
                                        cancelText="No"
                                        okButtonProps={
                                            { className: "bg-blue-500" }
                                        }
                                    >
                                        <button className='rounded-md px-4 py-1 text-sm bg-blue-600 text-white'>Facturar pedido</button>
                                    </Popconfirm>

                                }

                                {response?.pago_confirmado == 0 &&
                                    <Popconfirm
                                        title="Confirmar pago"
                                        description="¿Está seguro que desea confirmar la recepción del pago?"
                                        onConfirm={async () => await changeStatusRequest(response.id, { pago_confirmado: 1 })}
                                        okText="Si"
                                        cancelText="No"
                                        okButtonProps={
                                            { className: "bg-blue-500" }
                                        }
                                    >
                                        <button className='rounded-md px-4 py-1 text-sm bg-aprobado text-white'>Confirmar pago</button>
                                    </Popconfirm>

                                }

                                {response?.pago_confirmado == 0 && response?.pago_rechazado == 0 &&
                                    <Popconfirm
                                        title="Rechazar pago"
                                        description="¿Está seguro que desea rechazar el pago?"
                                        onConfirm={async () => await changeStatusRequest(response.id, { pago_rechazado: 1 })}
                                        okText="Si"
                                        cancelText="No"
                                        okButtonProps={
                                            { className: "bg-blue-500" }
                                        }
                                    >
                                        <button className='rounded-md px-4 py-1 text-sm bg-cancelado text-white'>Rechazar pago</button>
                                    </Popconfirm>
                                }

                                {/* {response?.pago_confirmado == 0 &&
    <Popconfirm
        title="Reembolsar pago"
        description="¿Está seguro que desea reembolsar el pago?"
        onConfirm={async () => await changeStatusRequest(response.id, { pago_reembolsado: 1 })}
        okText="Si"
        cancelText="No"
        okButtonProps={
            { className: "bg-blue-500" }
        }
    >
        <button className='rounded-md px-4 py-1 text-sm bg-orange-500'>Reembolsar pago</button>
    </Popconfirm>
} */}



                            </div>
                        </div>


                    </div>
                </div>
            </div >
        )
    }
}

