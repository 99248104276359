import LogoLoader from "assets/img/loader.png"

export default function Loader({ withWord = false, classname = '', small = false }) {
    if (small) {
        return (
            <div className={`flex items-start justify-center my-2 flex-col gap-2 ${classname}`} aria-label="Loading..." role="status">
                <img className="h-5 w-5 animate-spin" src={LogoLoader} />
                {withWord && <span className='text-xs text-gray-600'>Cargando</span>}
            </div>
        )
    } else {
        return (
            <div className={`flex items-center justify-center my-2 flex-col gap-2 ${classname}`} aria-label="Loading..." role="status">
                <img className="h-10 w-10 animate-spin" src={LogoLoader} />
                {withWord && <span className='text-xs text-gray-600'>Cargando</span>}
            </div>
        )
    }
}
