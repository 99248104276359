import Button from 'components/Button';
import InputUseForm from "components/InputUseForm";
import SelectUseForm from "components/SelectUseForm";
import { capitalize, toLower } from 'lodash';
import { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { storeEspecialidad } from "services/Especialidades";
import AbilitiesTreeSelectAdmin from 'components/abilities/AbilitiesTreeSelectAdmin';



export default function InputSpecialties({ specialties, isLoading, editData }) {
    const { register, handleSubmit, reset, setValue, formState: { errors } } = useForm()
    const [categories, setCategories] = useState([])
    const [parentId, setParentId] = useState(null)
    const [isEditing, setIsEditing] = useState(false)
    const [isEditingSubmit, setIsEditingSubmit] = useState(false)

    const onSubmit = async (data) => {
        const name = capitalize(data.especialidad)
        data.especialidad = name
        data.slug = toLower(name).replace(/ /g, '-')
        data.status = 1

        if (isEditingSubmit) {
            storeEspecialidad(data, editData.id).then((response) => {
                if (response) {
                    window.location.reload();
                } else {
                    console.error(response)
                }
            })
        } else {
            storeEspecialidad(data).then((response) => {
                if (response) {
                    window.location.reload();
                } else {
                    console.error(response)
                }
            })
        }

    }

    const handleChange = (value) => {
        setValue('parent_id', +value)
    }

    const handleCancel = () => {
        setValue('parent_id', null)
        setParentId(null)
        reset()
        setIsEditing(false)
    }

    useEffect(() => {
        if (editData) {
            setParentId(editData.parent_id)
            setValue('especialidad', editData.especialidad)
            setValue('parent_id', editData.parent_id)
            setValue('tag', editData.tag)
            setIsEditing(true)
        }
    }, [editData])

    useEffect(() => {
        if (!specialties) return

        const specialtyMap = specialties.reduce((acc, specialty) => {
            acc[specialty.id] = specialty;
            return acc;
        }, {})

        const getFullName = (specialty) => {
            const names = [];
            let currentSpecialty = specialty;

            while (currentSpecialty) {
                names.unshift(currentSpecialty.especialidad);
                currentSpecialty = currentSpecialty.parent_id
                    ? specialtyMap[currentSpecialty.parent_id]
                    : null;
            }

            return names.join(' / ')
        }

        const formattedCategories = specialties.map((specialty) => ({
            name: getFullName(specialty),
            value: specialty.id,
        }))

        setCategories(formattedCategories)
        reset()
    }, [specialties, reset])

    return (
        <div className="w-full flex min-h-40 h-fit rounded-b-md items-start flex-col pb-10">
            <form onSubmit={handleSubmit(onSubmit)} className="w-full bg-white rounded-b-md p-8 transition-all">
                <InputUseForm
                    label="Nombre"
                    name="especialidad"
                    register={register}
                    errors={errors}
                    placeholder="Ingrese el nombre de la especialidad"
                    rules={{ required: "El nombre es requerido" }}
                    className="w-full"
                />

                <InputUseForm
                    label="Etiqueta simplificada"
                    name="tag"
                    register={register}
                    errors={errors}
                    placeholder="Ingresar etiqueta simplificada"
                    className="w-full"
                />
                <span className="text-start block font-semibold mb-2 mt-2">Categoría padre</span>

                <AbilitiesTreeSelectAdmin
                    abilities={specialties}
                    onChange={handleChange}
                    selectedValue={parentId}
                />

                <SelectUseForm
                    label="Categoría padre"
                    name="parent_id"
                    register={register}
                    errors={errors}
                    className="w-full hidden"
                    options={categories}
                />

                {isEditing ?
                    <div className="w-full flex justify-start gap-4">
                        <Button isLoading={isLoading} type="submit" label="Actualizar especialidad" className="mt-8" onClick={() => setIsEditingSubmit(true)} />
                        <Button isLoading={isLoading} label="Cancelar" className="mt-8 bg-red-500 text-white" onClick={handleCancel} />
                    </div>
                    :
                    <Button isLoading={isLoading} type="submit" label="Crear especialidad" className="mt-8" onClick={() => setIsEditingSubmit(false)} />
                }
            </form>
        </div>
    );
}
