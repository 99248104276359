import { useQuill } from 'react-quilljs';
import Editor from 'components/editor';
import useConfiguracion from 'hooks/useConfiguracion';
import { useEffect } from 'react';
import useNotification from 'hooks/useNotification';

export default function Contact() {

    const theme = 'snow';
    const placeholder = 'Contenido de la página';

    const modules = {
        toolbar: [
            ['bold', 'italic', 'underline'],
            [{ 'header': 1 }, { 'header': 2 }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['link']
        ],
    };

    const { fetchCampo, storeCampo } = useConfiguracion()
    const { contextHolder, openNotification } = useNotification()
    const { quill, quillRef } = useQuill({ theme, modules, placeholder });

    const handleSave = () => {
        storeCampo('contacto', quillRef.current.firstChild.innerHTML)
        openNotification('Cargado correctamente', 'success', 'Cargado')
    }

    const loadData = async () => {
        const data = await fetchCampo("contacto")
        if (data) {
            if (quill) {
                quill.clipboard.dangerouslyPasteHTML(data.valor);
            }
        }
    }

    useEffect(() => {
        loadData()
    }, [quill])

    return (
        <>
            {contextHolder}

            <div className='flex flex-col items-start justify-center mt-4'>
                <label className='text-sm font-semibold mb-1'>Información de contacto</label>
                <span className='text-xs mb-4'>Está Información se mostrará en la pantalla de contacto.</span>
                <Editor classname="h-[400px] bg-white" quillRef={quillRef} />

                {/* {err.respuesta && <span className='text-sm text-red-600'>La respuesta es obligatoria</span>} */}

            </div>
            <div className='mt-16 text-end'>
                <button onClick={handleSave} className='text-sm px-6 bg-main font-bold py-2 rounded-lg hover:opacity-80'>Guardar</button>
            </div>

        </>

    )
}
