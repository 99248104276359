import { HttpGet, HttpPost, HttpPostImage, HttpPut, HttpDelete } from "./Http"


export const getPublicationsByState = async (payload) => {
    try {
        const data = await HttpPost(`admin/publicaciones`, JSON.stringify(payload))
        return data;
    } catch (error) {
        return error
    }
}

export const getPublication = async (id) => {
    try {
        const data = await HttpGet(`admin/publicaciones/${id}`, false)
        return data;
    } catch (error) {
        return error
    }
}

export const setStatusPublication = async (id, payload) => {
    try {
        const data = await HttpPost(`admin/publicaciones/${id}/estado`, JSON.stringify(payload))
        return data;
    } catch (error) {
        return error
    }
}

export const setConfirmPay = async (publicationId) => {
    try {
        const data = await HttpGet(`admin/publicaciones/${publicationId}/confirmar_pago`, false)
        return data;
    } catch (error) {
        return error
    }
}

export const getLocationsAccount = async (userId) => {
    try {
        const data = await HttpGet(`account/${userId}/ubicacion`, false)
        return data;
    } catch (error) {
        return error
    }
}

export const storePublication = async (payload, editId = null) => {
    try {
        let data;
        if (editId) {
            data = await HttpPut(`admin/publicacion/${editId}`, JSON.stringify(payload), true)
        } else {
            data = await HttpPost('admin/publicacion', JSON.stringify(payload), true)

        }
        return data;
    } catch (error) {
        return error
    }
}

export const getPostulationsByPublication = async (id) => {
    try {
        const data = await HttpGet(`admin/publicacion/${id}/postulados`, false)
        // console.log(data)
        return data;
    } catch (error) {
        return error
    }
}

export const getPostulatedsAll = async (limit = 10) => {
    try {
        const data = await HttpGet(`admin/postulados/${limit}`, false)
        // console.log(data)
        return data;
    } catch (error) {
        return error
    }
}

