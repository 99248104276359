import { HttpGet, HttpPost, HttpPostImage, HttpPut, HttpDelete } from "./Http"


export const getBloques = async () => {
    try {
        const data = await HttpGet(`admin/bloques`, false)
        return data;
    } catch (error) {
        return error
    }
}

export const setBloques = async (payload) => {
    try {
        const data = await HttpPost(`admin/bloques`, JSON.stringify(payload))
        return data;
    } catch (error) {
        return error
    }
}