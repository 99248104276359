import { Modal } from "antd"
import { useEffect, useState } from "react"
import useTables from 'hooks/useTables';
import { Divider, List, Popconfirm } from 'antd';
import { useForm } from "react-hook-form";

export default function ModalAbmVentajas({ isOpen, setIsOpen }) {
    const [ventajas, setVentajas] = useState([])
    const { response: itemsPlans, fetchData: fetchItems, store, destroy, update } = useTables("items-plans")
    const [isLoading, setIsLoading] = useState(false)
    const { register, handleSubmit, reset, formState: { errors }, setValue } = useForm({ defaultValues: { nombre: '', descripcion: '' } });
    const [editId, setEditId] = useState(null)

    const handleClose = () => {
        setIsOpen(false)
    }

    const fetchItemsPlans = async () => {
        setIsLoading(true)
        const data = await fetchItems(null, true)

        setVentajas(data)
        setIsLoading(false)
    }

    const fetchVentaja = async () => {

        const data = await fetchItems(editId, true)
        if (data) {
            setValue("nombre", data.nombre)
            setValue("descripcion", data.descripcion)
        }

    }

    const handleSave = async (data) => {

        if (editId) {
            await update(data, editId, () => {
                fetchItemsPlans()
                setEditId(null)
                reset()
            })
        } else {
            await store(data, () => {
                fetchItemsPlans()
                reset()
            })
        }
    }

    useEffect(() => {
        if (isOpen) {
            fetchItemsPlans()
        }
    }, [isOpen])

    useEffect(() => {
        if (editId) {
            fetchVentaja()
        }
    }, [editId])

    return (
        <Modal
            width="50%"
            open={isOpen}
            onOk={handleClose}
            onCancel={handleClose}
            className="top-4"
            footer={[]}
        >
            <div className="flex gap-4">
                <List
                    className="w-full"
                    loading={isLoading}
                    itemLayout="horizontal"
                    // loadMore={loadMore}
                    dataSource={ventajas}

                    renderItem={(item) => (
                        <List.Item
                            actions={[
                                <a onClick={() => setEditId(item.id)}>Editar</a>,
                                <Popconfirm
                                    title="Eliminar"
                                    description="¿Está seguro que desea eliminar el registro?"
                                    onConfirm={() => {
                                        destroy(item.id)
                                        fetchItemsPlans()
                                    }}
                                    okText="Si, Eliminar"
                                    cancelText="No"
                                    okButtonProps={{
                                        className: "bg-red-500 hover:bg-red-400"
                                    }}
                                >
                                    <a key="list-loadmore-more">Eliminar</a>
                                </Popconfirm>
                            ]}

                        >
                            <List.Item.Meta
                                className="py-0"
                                title={item.nombre}
                                description={item.descripcion}
                            />

                        </List.Item>
                    )}
                />
                <div className="w-[50%]">
                    <div className='flex flex-col items-start justify-center'>
                        <label className='text-sm font-semibold mb-1'>Título</label>
                        <input
                            {...register("nombre", { required: true })}
                            className='border px-4 py-1 rounded-lg w-full'
                        />
                        {errors.nombre && <span className='text-sm text-red-600'>El título es requerido</span>}
                    </div>

                    <div className='flex flex-col items-start justify-center mt-4'>
                        <label className='text-sm font-semibold mb-1'>Descripción</label>
                        <textarea
                            {...register("descripcion")}
                            className='border px-4 py-1 rounded-lg w-full min-h-[200px]'
                        ></textarea>
                    </div>

                    <div className="flex gap-2 mt-4">

                        <button className="bg-main txt-xs px-4 py-1 rounded-lg" onClick={handleSubmit(handleSave)}>Guardar</button>
                        <button className="bg-red-500 txt-xs px-4 py-1 rounded-lg" onClick={handleClose}>Cancelar</button>
                    </div>
                </div>

            </div>
        </Modal>
    )
}
