
// import { useAuth } from '@hooks/useAuth';
import { getUsuarios, getUsuario, verifyAndActivate, userChagePlan, deleteUserData } from "services/Usuarios";
import { useState } from "react";


const useUsers = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [response, setResponse] = useState([])
    const [error, setError] = useState(null)

    // Ok
    const fetchData = async (id = null, withReturn = false) => {
        setError(null)
        setIsLoading(true)

        try {
            let data;

            if (id) {
                data = await getUsuario(id)
                // setIsLoading(false)
            } else {
                data = await getUsuarios()
            }

            if (withReturn) {
                setResponse(id ? data : data)

                setIsLoading(false)

                return id ? data[0] : data
            }

            setResponse(id ? data : data)
            setIsLoading(false)
        } catch (error) {
            console.log(error)
            setError(error)
        } finally {
            setIsLoading(false)
        }

    }

    const verifyAccount = async (id, payload) => {
        await verifyAndActivate(id, payload)
    }

    const changePlan = async (userId, planId) => {
        await userChagePlan(userId, planId)
    }

    const deleteUser = async (userId) => {
        await deleteUserData(userId)
    }

    return { response, changePlan, isLoading, error, fetchData, verifyAccount, deleteUser }
}


export default useUsers