import { Table } from 'antd';
import { useEffect, useState } from 'react';
import { FaEdit } from "react-icons/fa";
import { getTree } from 'services/Especialidades';

const TableSpecialties = ({ isLoading, error, data, onEdit }) => {
  const [dataSource, setDataSource] = useState([]);
  const [tree, setTree] = useState([]);

  const fetchTree = async () => {
    const data = await getTree();
    setTree(data);
  };

  useEffect(() => {
    fetchTree()
  }, []);

  useEffect(() => {
    const hierarchy = buildHierarchy(data);
    const flatData = flattenHierarchy(hierarchy);
    setDataSource(flatData);
  }, [data]);

  const buildHierarchy = (items, parentId = null) => {
    return items
      .filter(item => item.parent_id === parentId)
      .map(item => {
        const children = buildHierarchy(items, item.id);
        return children.length ? { ...item, children } : item;
      });
  };

  const flattenHierarchy = (items, level = 0) => {
    return items.reduce((acc, item) => {
      acc.push({
        key: item.id,
        especialidad: `${'-'.repeat(level)} ${item.especialidad}`,
        status: item.status,
      });
      if (item.children) {
        acc = acc.concat(flattenHierarchy(item.children, level + 1));
      }
      return acc;
    }, []);
  };

  const handleClick = (id) => {
    const especialidadEdit = getEspecialidad(id);
    if (onEdit) {
      onEdit(especialidadEdit)
    }
  };

  const getEspecialidad = (id) => {
    return data.find((item) => item.id === id);
  };

  const columns = [
    {
      title: 'Especialidad',
      dataIndex: 'especialidad',
      key: 'especialidad',
      render: (text) => <a>{text}</a>,
    },
    {
      title: 'Etiqueta simplificada',
      dataIndex: 'tag',
      key: 'tag',
      render: (text, record) => <a>{tree?.tree?.tag[record.key]}</a>,
    },
    {
      title: 'Acciones',
      dataIndex: 'acciones',
      key: 'acciones',
      render: (text, record) => (
        <a
          href={`especialidades/${record.key}`}
          onClick={(e) => {
            e.preventDefault();
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

            handleClick(record.key);
          }}
        >
          <FaEdit />
        </a>
      ),
    },
  ];

  return (
    <div className='w-full flex min-h-40 rounded-b-md items-start flex-col pb-10'>
      <Table
        columns={columns}
        dataSource={dataSource}
        loading={isLoading}
        className="w-full bg-white rounded-b-md p-8 transition-all"
        rowKey="key"
        pagination={false}
      />
    </div>
  );
};

export default TableSpecialties;
