import { getItem, removeItem, setItem } from "../storage/UserAsyncStorage";
import React, { createContext, useContext, useEffect, useState } from "react";
import { routesNames } from "../utils/Constants";
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {

    const [authed, setAuthed] = useState(false);

    const [userData, setUserData] = useState({
        isLogged: false,
        name: '',
        token: ''
    })

    const navigate = useNavigate();


    useEffect(() => {
        fetchUser()
    }, [])

    const fetchUser = async () => {

        const data = await getItem()
        // console.log("11PASO")
        if (data) {
            const dataUser = JSON.parse(data);
            let today = new Date();

            if (today.getTime() > dataUser.expiration) {
                // console.log("EXPIRO")
                logout()
                return
            }
            // console.log(data)
            setUserData({ ...JSON.parse(data), isLogged: true })

        } else {
            // logout()
        }
    }

    const changeName = async (name, lastName) => {
        const data = await getItem()
        if (data) {
            const newData = { ...JSON.parse(data), isLogged: true, name: name, lastName: lastName, fullName: `${name} ${lastName}` }
            await setItem(newData)

            setUserData(newData)
        }
    }

    const changeImage = async (image) => {
        const data = await getItem()
        if (data) {
            const account = data.account
            const newData = { ...JSON.parse(data), isLogged: true, account: { ...account, logo_url: image } }
            await setItem(newData)

            setUserData(newData)
        }
    }

    const login = async (data) => {
        let today = new Date();

        //Tiempo de sesion, 3 horas (10800000 milisegundos)
        const loginData = {
            ...data, isLogged: true, expiration: today.getTime() + 10800000
        }

        await setItem(loginData)
        setUserData(loginData)
    };

    const logout = async () => {

        const result = await removeItem()

        setUserData({ isLogged: false, token: null, name: null })
        navigate(routesNames.LOGIN)
    };


    return (
        <AuthContext.Provider value={{ authed, setAuthed, login, logout, userData, changeName, changeImage }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);