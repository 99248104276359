import { Radio, Collapse } from 'antd';
import TransferUser from './components/TransferUser';
import { useQuill } from 'react-quilljs';
import Editor from 'components/editor';
import { useState } from 'react';
import Button from 'components/Button';
import useNotifications from 'hooks/useNotifications';
import useNotification from 'hooks/useNotification';

export default function CustomNotifications() {

    const { sendCustom, isLoading } = useNotifications()
    const { contextHolder, openNotification } = useNotification()

    const theme = 'snow';
    const placeholder = 'Contenido de la notificación';

    const modules = {
        toolbar: [
            ['bold', 'italic', 'underline'],
            [{ 'header': 1 }, { 'header': 2 }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['link']
        ],
    };

    const { quill, quillRef } = useQuill({ theme, modules, placeholder });
    const [title, setTitle] = useState("")
    const [isTransferVisible, setIsTransferVisible] = useState(false)
    const [type, setType] = useState("")
    const [radioUser, setRadioUser] = useState()
    const [users, setUsers] = useState([])
    const [error, setError] = useState(null)

    const onSubmit = async () => {

        setError(null)

        if (radioUser == '' && users.length == 0) {
            setError("Debe seleccionar al menos un usuario!")
            return;
        }

        if (title == '') {
            setError("Debe ingresar un titulo!")
            return;
        }

        if (quillRef.current.firstChild.innerHTML == '<p><br></p>') {
            setError("Debe ingresar un mensaje!")
            return;
        }


        const payload = {
            users,
            option: radioUser,
            title: title,
            message: quillRef.current.firstChild.innerHTML,
        }

        // console.log(payload)

        await sendCustom(payload, () => {
            openNotification('Enviado correctamente', 'success', 'Enviado')
            if (quill) {
                quill.clipboard.dangerouslyPasteHTML("");
            }
            setTitle("")
            setUsers([])
        })
    }

    return (
        <div className='flex flex-col gap-4'>
            {contextHolder}

            <div className='flex flex-col gap-1'>
                <span>Seleccionar destinatarios</span>
                <Radio.Group defaultValue="a" >
                    <Radio.Button onClick={() => {
                        setIsTransferVisible(false)
                        setRadioUser("A")
                    }} value="a">Todos los usuarios</Radio.Button>

                    <Radio.Button onClick={() => {
                        setIsTransferVisible(false)
                        setRadioUser("I");
                    }} value="b">Todas las instituciones</Radio.Button>

                    <Radio.Button onClick={() => {
                        setIsTransferVisible(false)
                        setRadioUser("IG");
                    }} value="c">Instituciones GOLD</Radio.Button>

                    <Radio.Button onClick={() => {
                        setIsTransferVisible(false)
                        setRadioUser("IS");
                    }} value="g">Instituciones Standard</Radio.Button>

                    <Radio.Button onClick={() => {
                        setIsTransferVisible(false)
                        setRadioUser("P");
                    }} value="d">Todos los postulantes</Radio.Button>

                    <Radio.Button onClick={() => {
                        setIsTransferVisible(true)
                        setRadioUser("");
                    }} value="e">Seleccionar</Radio.Button>
                </Radio.Group>
            </div>

            <div className='flex flex-col gap-4 items-start'>
                {isTransferVisible &&
                    <Collapse
                        className='w-full'
                        items={[
                            {
                                key: '1',
                                label: 'Seleccionar usuarios',
                                children: <div>
                                    <Radio.Group defaultValue="a" className='mb-4'>
                                        <Radio.Button onClick={() => setType("")} value="a">Todos</Radio.Button>
                                        <Radio.Button onClick={() => setType("P")} value="b">Postulantes</Radio.Button>
                                        <Radio.Button onClick={() => setType("I")} value="c">Instituciones</Radio.Button>
                                    </Radio.Group>
                                    <TransferUser type={type} setSelectedUsers={setUsers} />

                                </div>,
                            },
                        ]}
                    />
                }
                <input placeholder='Titulo' value={title} onChange={(e) => setTitle(e.target.value)} type="text" className="border w-full rounded-md p-2 text-sm" />

                <div className="flex gap-4 w-full">
                    <div className='h-[350px] w-full'>
                        <Editor classname=" bg-white w-full h-full" quillRef={quillRef} />
                    </div>
                    <div className="w-[40%]">

                        <Collapse defaultActiveKey={['0']} accordion>
                            <Collapse.Panel header="Referencias de institución">
                                <div className="flex flex-col gap-0">
                                    <span>Nombre : {`{`}i.nombre{`}`}</span>
                                    <span>Email : {`{`}i.email{`}`}</span>
                                </div>
                            </Collapse.Panel>

                            <Collapse.Panel header="Referencias de postulante">
                                <div className="flex flex-col gap-0">
                                    <span>Nombre : {`{`}o.nombre{`}`}</span>
                                    <span>Email : {`{`}o.email{`}`}</span>
                                </div>
                            </Collapse.Panel>

                            <Collapse.Panel header="Referencias de usuario">
                                <div className="flex flex-col gap-0">
                                    <span>Nombre : {`{`}u.nombre{`}`}</span>
                                    <span>Email : {`{`}u.email{`}`}</span>
                                    <span>Tipo : {`{`}u.tipo{`}`}</span>
                                </div>
                            </Collapse.Panel>

                            <Collapse.Panel header="Otras referencias">
                                <div className="flex flex-col gap-0">
                                    <span>Link recuperar contraseña : {`{`}a.recuperar{`}`}</span>
                                </div>
                            </Collapse.Panel>
                        </Collapse>

                    </div>


                </div>
                <div className='flex gap-2 items-center'>
                    <Button
                        isLoading={isLoading}
                        label="Enviar"
                        className='mt-10 !px-8'
                        onClick={onSubmit}
                        textLoading="Enviando"
                    />

                    {error && <span className='mt-10 text-sm text-red-500'>{error}</span>}
                </div>
            </div>
        </div>
    )
}
