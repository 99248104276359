export const getFullMonth = (date) => {
    let month = date.getMonth() + 1 + ""
    if (month.length < 2) {
        month = `0${month}`
    }

    return month
}

export const formatDateFull = (date) => {
    const fecha = new Date(date);

    return `${getFullDay(fecha, true)}/${getFullMonth(fecha)}/${fecha.getFullYear()} ${fecha.getHours()}:${fecha.getMinutes()}`
}

export const getFullDay = (date, rest = false) => {
    let day;
    if (rest) {
        day = date.getDate() + ""
    } else {
        day = date.getDate() + 1 + ""
    }
    // let day = date.getDate() + 1 + ""
    if (day.length < 2) {
        day = `0${day}`
    }

    return day
}

export const getNameFromObject = (list, value, fieldName = 'name', esp = false) => {
    let name = ""
    list.map((item) => {
        if (esp) {
            if (item.id == value) {
                name = item[fieldName]
            }
        }

        if (item.value == value) {
            name = item[fieldName]
        }
    })

    return name
}

export const formatCurNumber = (value) => {
    return new Intl.NumberFormat('de-DE').format(value);
}