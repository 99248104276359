
// import { useAuth } from '@hooks/useAuth';
import { getRequestPending, getRequestPlan, setConfirmPay, setStatusRequest } from "services/Pedidos";
import { useState } from "react";


const useRequests = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [response, setResponse] = useState([])
    const [error, setError] = useState(null)

    // Ok
    const fetchData = async (id = null, withReturn = false, filters = {}) => {
        setError(null)
        setIsLoading(true)

        try {
            let data;

            if (id) {
                data = await getRequestPlan(id)
                setIsLoading(false)
            } else {
                // console.log(filters)
                data = await getRequestPending(filters)
            }

            // console.log(data)
            if (withReturn) {
                setResponse(data.data)
                setIsLoading(false)
                return id ? data[0] : data
            }

            setResponse(id ? data : data)
            setIsLoading(false)
        } catch (error) {
            console.log(error)
            setError(error)
        } finally {
            setIsLoading(false)
        }

    }

    const confirmPay = async (userId) => {
        // console.log(payload)
        const data = await setConfirmPay(userId)
        return data
    }

    const changeStatus = async (id, payload) => {
        // console.log(payload)
        const data = await setStatusRequest(id, payload)
        return data
    }

    return { response, isLoading, error, fetchData, confirmPay, changeStatus }
}


export default useRequests