
// import { useAuth } from '@hooks/useAuth';
import { searchNotifications, changeStatusNotification, custom, updateNotification } from "services/Notificaciones";
import { useState } from "react";


const useNotifications = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [response, setResponse] = useState([])
    const [error, setError] = useState(null)

    const find = async (payload, withReturn = false) => {

        setIsLoading(true)
        const data = await searchNotifications(payload)

        if (withReturn) {
            setIsLoading(false)
            return data
        } else {
            setResponse(data)
        }

        setIsLoading(false)
    }

    const changeStatus = async (id, type) => {
        await changeStatusNotification(id, type)
    }

    const update = async (id, payload, onSuccess) => {

        setError(null)
        setIsLoading(true)

        try {
            const data = await updateNotification(id, payload)

            if (data.error) {
                setError(data.data[0])
            } else {
                // fetchData()
                onSuccess()
            }

            setIsLoading(false)
        } catch (error) {
            console.log(error)
            setError(error)
        } finally {
            setIsLoading(false)
        }
    }

    const sendCustom = async (payload, onSuccess) => {
        setError(null)
        setIsLoading(true)

        try {
            const data = await custom(payload)

            if (data.error) {
                setError(data.data[0])
            } else {
                // fetchData()
                onSuccess()
            }

            setIsLoading(false)
        } catch (error) {
            console.log(error)
            setError(error)
        } finally {
            setIsLoading(false)
        }
    }


    return { response, isLoading, error, find, changeStatus, update, sendCustom }
}


export default useNotifications