import React from 'react'
import PostulationsTable from './components/PostulationsTable'

export default function index() {
    return (
        <div className="mt-1 flex flex-col items-start w-full">
            <PostulationsTable />
        </div>
    )
}
