import { HttpGet, HttpDelete, HttpPut, HttpPost } from "./Http"


export const getEspecialidades = async () => {
    try {
        // const data = await HttpGet(`admin/especialidades`, false)
        const data = await HttpGet(`especialidades`, false)
        return data;
    } catch (error) {
        return error
    }
}

export const getEspecialidad = async (id) => {
    try {
        const data = await HttpGet(`admin/especialidades/${id}`, false)
        return data;
    } catch (error) {
        return error
    }
}

export const getAllEspecialidades = async () => {
    try {
        // const data = await HttpGet(`admin/especialidades`, false)
        const data = await HttpGet(`especialidades`, false)
        return data;
    } catch (error) {
        return error
    }
}

export const storeEspecialidad = async (payload, editId = null) => {
    try {
        let data;
        if (editId) {
            data = await HttpPut(`especialidades/${editId}`, JSON.stringify(payload), true)
        } else {
            data = await HttpPost('especialidades', JSON.stringify(payload), false)
        }
        return data;
    } catch (error) {
        return error
    }
}


export const deleteUserData = async (userId) => {
    try {
        const data = await HttpDelete(`admin/usuarios/${userId}`)
        return data;
    } catch (error) {
        return error
    }
}

export const getTree = async () => {
    try {
        const data = await HttpGet(`arbol_especialidades`, false)
        // const data = await HttpGet(`especialidades`, false)
        return data;
    } catch (error) {
        return error
    }
}
