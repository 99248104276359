import { Popconfirm, Space, Tag } from 'antd';
import Loader from 'components/loader';
import useTables from 'hooks/useTables';
import { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { BiEdit, BiTrash } from "react-icons/bi";
import { formatCurNumber } from "utils/Utils"

export default function PublicationsSettings() {
    const [isOpen, setIsOpen] = useState(false)
    const [editId, setEditId] = useState(null)
    const [ventajas, setVentajas] = useState([])
    const { register, handleSubmit, reset, formState: { errors }, setValue } = useForm();
    const { response, isLoading, fetchData, store, destroy, update } = useTables("packs")

    const fetchPacks = async () => {
        const data = await fetchData(editId)
        // console.log(data)

        if (data) {
            setValue("nombre", data.nombre)
            setValue("precio", data.precio)
            setValue("vigencia", data.vigencia)
            setValue("cantidad_publicaciones", data.cantidad_publicaciones)
            setValue("ventajas", data.ventajas)
            setVentajas(data.ventajas.split(","))
        }
    }

    useEffect(() => {
        if (editId) {
            fetchPacks()
        } else {
            fetchData()
        }
    }, [editId])

    const handleSave = async (data) => {
        // console.log(data)
        if (editId) {
            await update(data, editId, () => {
                reset()
                setIsOpen(false)
                setEditId(null)
            })
        } else {
            await store(data, () => {
                setIsOpen(false)
                fetchData()
                reset()
            })

        }
    }

    return (
        <div>
            <div className="mt-3 flex flex-col items-start">
                {!isOpen && <button onClick={() => setIsOpen(true)} className='bg-main px-5 py-2 rounded-lg text-sm font-semibold'>+ Agregar nuevo pack</button>}
            </div>

            <div className={(isOpen ? '' : 'hidden') + ' bg-white p-4 rounded-lg'}>
                <div className='flex flex-col items-start justify-center'>
                    <label className='text-sm font-semibold mb-1'>Nombre</label>
                    <input
                        {...register("nombre", { required: true })}
                        className='border px-4 py-1 rounded-lg w-full'
                    />
                    {errors.nombre && <span className='text-sm text-red-600'>El nombre es obligatorio</span>}
                </div>

                <div className='flex gap-2 items-center justify-start mt-4'>

                    <div className='flex flex-col items-start justify-center'>
                        <label className='text-sm font-semibold mb-1'>Precio</label>
                        <input
                            {...register("precio", { required: true })}
                            className='border px-4 py-1 rounded-lg w-full'
                        />
                        {errors.precio && <span className='text-sm text-red-600'>El precio es obligatorio</span>}
                    </div>

                    <div className='flex flex-col items-start justify-center'>
                        <label className='text-sm font-semibold mb-1'>Vigencia (en días)</label>
                        <input
                            {...register("vigencia", { required: true })}
                            className='border px-4 py-1 rounded-lg w-full'
                        />
                        {errors.vigencia && <span className='text-sm text-red-600'>La vigencia es obligatoria</span>}
                    </div>

                    <div className='flex flex-col items-start justify-center'>
                        <label className='text-sm font-semibold mb-1'>Cantidad de publicaciones</label>
                        <input
                            {...register("cantidad_publicaciones", { required: true })}
                            className='border px-4 py-1 rounded-lg w-full'
                        />
                        {errors.cantidad_publicaciones && <span className='text-sm text-red-600'>La cantidad de publicaciones es obligatoria</span>}
                    </div>


                </div>

                <div className='flex flex-col mt-4'>
                    <label className='text-sm font-semibold mb-1'>Ventajas</label>
                    <Space size={[0, 8]} wrap>
                        {ventajas.map((ventaja, idx) => (
                            <Tag key={idx} className='bg-main' closeIcon onClose={() => { }}>
                                {ventaja}
                            </Tag>
                        ))}
                    </Space>
                    {/* <input
                        onKeyDown={(e) => {
                            if (e.key == "Enter" && e.target.value != "") {
                                const vent = ventajas
                                vent.push(e.target.value)
                                setVentajas(vent)
                                console.log("AGREGA VENTAJA")
                            }
                        }}
                        className='border px-4 py-1 rounded-lg w-full mt-2'
                    /> */}
                </div>

                <input
                    {...register("ventajas", { required: true })}
                    className='border px-4 py-1 rounded-lg w-full mt-4'
                />


                <div className='flex items-end justify-end gap-3 mt-3'>
                    <button onClick={() => {
                        setIsOpen(false)
                        reset()
                    }} className='bg-gray-50 text-sm px-6 font-bold py-2 rounded-lg hover:opacity-80'>Cancelar</button>
                    <button onClick={handleSubmit(handleSave)} className='text-sm px-6 bg-main font-bold py-2 rounded-lg hover:opacity-80'>Guardar</button>
                </div>
            </div>

            <span className='font-semibold text-lg mt-5 block bg-white px-4 py-2 rounded-lg'>Packs cargados</span>

            {!isLoading && response.length == 0 && <span className='block text-center mt-4 text-sm text-gray-500'>No hay packs cargados</span>}
            {isLoading && <Loader withWord={true} classname='mt-10' />}

            <div className='flex gap-3 mt-4'>
                {!isLoading && response.length > 0 && response.map((item, idx) => {
                    return <div key={idx} className='flex flex-col justify-between items-start p-4 bg-white rounded-lg gap-4 min-w-[250px]'>
                        <div className='flex flex-col items-start py-2 px-4 bg-white gap-2'>
                            <span className='font-semibold'>{item.nombre}</span>
                            <span className='text-xs'>${formatCurNumber(item.precio)} por publicación</span>
                            <span className='text-xs'>${formatCurNumber(item.total)} {item.off > 0 && <span className='bg-main p-1 rounded-lg'>{item.off}% off</span>}</span>

                            <hr />

                            <span className='text-xs'>Cantidad: {item.cantidad_publicaciones}</span>
                            <span className='text-xs'>Vigencia publicación: {item.vigencia}</span>
                        </div>

                        <div className='flex items-start justify-between gap-2 px-4 w-full'>
                            <button
                                className="text-xs flex gap-2 text-gray-500 bg-transparent hover:outline-none active:outline-0"
                                onClick={() => { setEditId(item.id); setIsOpen(true) }}>
                                <BiEdit className="text-lg" />Editar</button>
                            <Popconfirm
                                title="Eliminar"
                                description="¿Está seguro que desea eliminar el registro?"
                                onConfirm={() => destroy(item.id)}
                                okText="Si, Eliminar"
                                cancelText="No"
                                okButtonProps={{
                                    className: "bg-red-500 hover:bg-red-400"
                                }}
                            >
                                <button
                                    className={`${item.id == 1 && 'hidden'} text-xs flex gap-2 text-gray-500 bg-transparent hover:outline-none active:outline-0`}
                                >
                                    <BiTrash className="text-lg" />
                                    Borrar
                                </button>
                            </Popconfirm>
                        </div>
                    </div>
                })}
            </div>
        </div>
    )
}
