import { Input, Select, Table, Tag } from "antd";
import useRequests from "hooks/useRequests";
import { useEffect, useState } from "react";
import { BiSearchAlt } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import { routesNames } from "utils/Constants";
import { formatCurNumber, getFullDay, getFullMonth } from "utils/Utils";


const RequestsTable = () => {
    let navigate = useNavigate();

    const [datasource, setDatasource] = useState([]);
    const [value, setValue] = useState(null)
    const [filters, setFilters] = useState({ tipo: '' })
    const { isLoading, response, fetchData } = useRequests()

    const columns = [
        {
            title: 'Tipo',
            dataIndex: 'es_plan',
            key: 'es_plan',
            render: (text) => {
                if (text) {
                    return <Tag color="lime">Plan</Tag>
                } else {
                    return <Tag color="blue">Publicación</Tag>
                }
            }
        },
        // {
        //     title: 'Importe',
        //     dataIndex: 'total',
        //     key: 'total',
        //     render: (text) => {
        //         if (!text || text == 0) {
        //             return "Free"
        //         } else {
        //             return `$ ${formatCurNumber(text)}`
        //         }
        //     }
        // },
        // {
        //     title: 'Pago',
        //     dataIndex: 'confirmado',
        //     render: (text, record) => {
        //         if (text == 1) {
        //             return <Tag color="green">Confirmado</Tag>
        //         } else {
        //             return <Tag color="orange">Pendiente</Tag>
        //         }
        //     }
        // },
        {
            title: 'Estado',
            dataIndex: 'estado',
            render: (text, record) => {
                if (record.es_plan == 1) {
                    if (record.confirmado == 1) {
                        return <Tag color="green">Confirmado</Tag>
                    } else {
                        return <Tag color="orange">Pendiente</Tag>
                    }
                } else {
                    return <Tag color={record.estado?.estado?.color}>{record.estado?.estado?.nombre}</Tag>
                }
            }
        },
        {
            title: 'Cuenta',
            dataIndex: 'cuenta',
            key: 'cuenta',
            render: (text, record) => {
                return <Link to={`${routesNames.USER}/${record.cuenta.user_id}`} className="text-blue-700">{record.cuenta.nombre}</Link>

            }
        },
        {
            title: 'CUIT',
            dataIndex: 'cuit',
            key: 'cuit',
            render: (text, record) => {
                return record.data_facturacion?.cuit
            }
        },
        {
            title: 'Razon Social',
            dataIndex: 'razon',
            key: 'razon',
            render: (text, record) => {
                return record.data_facturacion?.razon_social
            }
        },
        {
            title: 'Fecha',
            dataIndex: 'fecha',
            render: (text) => {
                const date = new Date(text);
                return `${getFullDay(date, true)}/${getFullMonth(date)}/${date.getFullYear()}`
            }
        },
        // {
        //     title: 'Medio de Pago',
        //     dataIndex: 'address',
        //     key: 'address',
        //     render: (text, record) => {
        //         return record.medio_pago?.nombre
        //     }
        // },
        // {
        //     title: 'Estado',
        //     dataIndex: 'estado',
        //     render: (text, record) => {
        //         if (record.estado) {
        //             return <Tag color={`${record.estado.estado.color}`}>{record.estado.estado.nombre}</Tag>
        //         } else {
        //             return ""
        //         }
        //     }
        // },
        {
            title: 'Detalle',
            dataIndex: 'detalle',
            render: (text, record) => {
                if (record.es_plan) {
                    return <button onClick={() => navigate(`${routesNames.PLAN}/${record.id}`)} className="bg-main text-sm w-full rounded-xl">Ver</button>
                } else {
                    return <button onClick={() => navigate(`${routesNames.PUBLICATION}/${record.publicacion}`)} className="bg-main text-sm w-full rounded-xl">Ver</button>
                }
            }
        },
    ];

    const fetchRequests = async () => {
        const data = await fetchData(null, true, filters)
        setDatasource(data?.data)
    }

    useEffect(() => {
        // console.log(filters)
        fetchRequests()
        // fetchAccounts()
    }, [filters])

    return (
        <>
            <div className="bg-transparent rounded-lg w-full mb-1 flex gap-4">

                <Select defaultValue="todos"
                    style={{
                        width: 120,
                        height: 47
                    }}
                    placeholder="Tipo"
                    // className="h-full"
                    // allowClear
                    // onClear={() => setFilters({ ...filters, tipo: '' })}
                    onChange={(value) => setFilters({ ...filters, tipo: value })}
                    options={[
                        {
                            value: 'todos',
                            label: 'Todos',
                        },
                        {
                            value: 'plan',
                            label: 'Plan',
                        },
                        {
                            value: 'publicacion',
                            label: 'Publicación',
                        }
                    ]}
                />


                <Input
                    // onClear={() => setFilters({ ...filters, tipo: '' })}

                    allowClear
                    placeholder="Buscar por Cuenta,Razon Social o CUIT"
                    prefix={<BiSearchAlt className="text-xl" />}
                    value={value}
                    // size="small"
                    className="p-3 w-full rounded-lg mb-2"

                    onChange={e => {
                        const currValue = e.target.value;
                        setValue(currValue);

                        // console.log(response)
                        const filteredData = response.filter(entry =>
                            entry.cuenta?.nombre?.toLowerCase().includes(currValue.toLowerCase()) || entry.data_facturacion?.razon_social.toLowerCase().includes(currValue.toLowerCase()) || entry.data_facturacion?.cuit.toLowerCase().includes(currValue.toLowerCase())

                        );
                        setDatasource(filteredData);
                    }}
                />
            </div>

            <Table
                loading={isLoading}
                dataSource={datasource}
                columns={columns}
                className="w-full"
                rowKey={(record) => record.id + (record.es_plan ? '1' : '0')}
                size="small"
                pagination={
                    {
                        pageSize: 20,
                        showSizeChanger: false,

                    }
                }
                locale={{ emptyText: "No hay resultados" }}

            />
        </>
    )
};

export default RequestsTable;
