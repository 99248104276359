import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import useNotifications from 'hooks/useNotifications';
import { List, Switch } from 'antd';
import ModalNotification from './components/ModalNotification';

export default function Notifications() {
    const location = useLocation();
    const { find, response, isLoading, changeStatus } = useNotifications()
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [editId, setEditId] = useState(null)
    const [notifications, setNotifications] = useState([])

    const fetchNotifications = async () => {
        // console.log(location)
        const uris = location.pathname?.split("/")
        const type = uris.at(-1)

        // const data = 
        await find({
            administrador: type == 'administrador' ? 1 : 0,
            postulante: type == 'postulante' ? 1 : 0,
            institucion: type == 'institucion' ? 1 : 0,
        })

        // console.log(data)

        // setNotifications(data?.data)

    }

    useEffect(() => {
        fetchNotifications()
    }, [location])


    return (
        <div className='bg-white w-full px-4 rounded-lg'>
            <ModalNotification isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} editId={editId} />
            <List
                className="demo-loadmore-list"
                loading={isLoading}
                itemLayout="horizontal"
                dataSource={response?.data}
                renderItem={(item) => (
                    <List.Item
                        actions={[
                            <div className='flex items-center gap-1'>
                                <span>Email</span>
                                <Switch checked={item.email} onChange={async () => {
                                    await changeStatus(item.id, "email")
                                    fetchNotifications()
                                    // setChangeStatus(item.id)
                                }} />
                            </div>,
                            <div className='flex items-center gap-1'>
                                <span>Notificación</span>
                                <Switch checked={item.app} onChange={async () => {
                                    await changeStatus(item.id, "app")
                                    fetchNotifications()
                                    // setChangeStatus(item.id)
                                }} />
                            </div>,
                            <a onClick={() => {
                                setEditId(item.id)
                                setIsModalOpen(true)
                            }}>Editar</a>,
                        ]}
                    >
                        <List.Item.Meta
                            title={<span className='font-bold'>{item.nombre}</span>}
                            description={item.detalle}
                        />
                    </List.Item>
                )}
            />
        </div>
    )
}
