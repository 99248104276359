import { Table, Tag, Transfer } from 'antd';
import useUsers from "hooks/useUsers";
import difference from 'lodash/difference';
import { useEffect, useState } from 'react';

const TableTransfer = ({ leftColumns, rightColumns, isLoading, ...restProps }) => (
    <Transfer {...restProps}>
        {({
            direction,
            filteredItems,
            onItemSelectAll,
            onItemSelect,
            selectedKeys: listSelectedKeys,
            disabled: listDisabled,
        }) => {
            const columns = direction === 'left' ? leftColumns : rightColumns;
            const rowSelection = {
                getCheckboxProps: (item) => ({
                    disabled: listDisabled || item.disabled,
                }),
                onSelectAll(selected, selectedRows) {
                    const treeSelectedKeys = selectedRows
                        .filter((item) => !item.disabled)
                        .map(({ key }) => key);
                    const diffKeys = selected
                        ? difference(treeSelectedKeys, listSelectedKeys)
                        : difference(listSelectedKeys, treeSelectedKeys);
                    onItemSelectAll(diffKeys, selected);
                },
                onSelect({ key }, selected) {
                    onItemSelect(key, selected);
                },
                selectedRowKeys: listSelectedKeys,
            };
            return (
                <div>
                    <span className='ml-2 font-semibold'>{direction == "left" ? "Usuario disponibles" : "Usuarios seleccionados"}</span>
                    <Table
                        rowSelection={rowSelection}
                        columns={columns}
                        dataSource={filteredItems}
                        loading={isLoading}
                        size="small"
                        pagination={{
                            showSizeChanger: false,
                        }}
                        style={{
                            pointerEvents: listDisabled ? 'none' : undefined,
                        }}
                        onRow={({ key, disabled: itemDisabled }) => ({
                            onClick: () => {
                                if (itemDisabled || listDisabled) return;
                                onItemSelect(key, !listSelectedKeys.includes(key));
                            },
                        })}
                    />
                </div>
            );
        }}
    </Transfer>
);

const columns = [
    {
        dataIndex: 'name',
        title: 'Nombre',
    },
    {
        dataIndex: 'tipo',
        title: 'Tipo',
    },
    {
        dataIndex: 'plan',
        title: 'Plan',
    }
];

const getName = (user) => {

    if (user.type == 'I') {
        if (user?.cuenta.length > 0) {
            return user.cuenta.razon_social
        } else {
            return user.name
        }
    } else {
        return user.name
    }

}

const getPlan = (user) => {
    if (user.type == 'I') {
        if (user?.plan) {
            if (user.plan.plan_id == 1) {
                return <Tag color='gold'>{user.plan.detalle.nombre}</Tag>
            } else {
                return <Tag color='green'>{user.plan.detalle.nombre}</Tag>
            }
        } else {
            return <Tag color='green'>Standard</Tag>
        }
    } else {
        return <></>
    }
}

const getTipo = (user) => {
    if (user.type == 'I') {
        return <Tag color='lime'>Institución</Tag>
    } else {
        return <Tag color='blue'>Postulante</Tag>
    }
}

const TransferUser = ({ setSelectedUsers, type = "" }) => {
    const { isLoading, fetchData } = useUsers()

    const [users, setUsers] = useState([])

    const originTargetKeys = users
        .filter((item) => Number(item.key) % 3 > 1)
        .map((item) => item.key);

    const [targetKeys, setTargetKeys] = useState(originTargetKeys);


    const fetchUsers = async () => {
        const data = await fetchData(null, true)

        if (data?.length > 0) {
            let tempData = data.filter((user) => user.type != 'A')
            if (type != "") {
                tempData = data.filter((user) => user.type == type)
            }
            const response = []

            tempData.map((user, idx) => {
                response.push({
                    key: user.id,
                    name: getName(user),
                    plan: getPlan(user),
                    tipo: getTipo(user)
                })
            })

            setUsers(response)
        }
    }

    useEffect(() => {
        setTargetKeys([]);
        setSelectedUsers([])
        fetchUsers()
    }, [type])

    const onChange = (nextTargetKeys) => {
        setTargetKeys(nextTargetKeys);
        setSelectedUsers(nextTargetKeys)
    };


    return (
        <>
            <TableTransfer
                dataSource={users}
                targetKeys={targetKeys}
                showSearch
                isLoading={isLoading}
                onChange={onChange}
                filterOption={(inputValue, item) =>
                    item.name.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1
                }
                leftColumns={columns}
                rightColumns={columns}
            />

        </>
    );
};

export default TransferUser;