
import { BiLoaderAlt } from "react-icons/bi";

export default function Button({ label, isLoading = false, onClick = null, className = '', textLoading = '', type = 'button' }) {

    return (
        <button
            onClick={onClick}
            type={type}
            disabled={isLoading}
            className={"text-sm px-6 py-1 rounded-lg bg-main text-cblack font-bold flex items-center gap-2 " + className + " " + (isLoading && " disabled:opacity-75 ")
            }
        > {isLoading && <BiLoaderAlt className="animate-spin" />} {isLoading && textLoading ? textLoading : label}</button >
    )
}
