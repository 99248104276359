/* eslint-disable */
import { useState } from "react";
import {
  MdBarChart,
  MdHome,
  MdNotificationsNone,
  MdOutlineShoppingCart,
  MdPerson,
  MdSettings,
  MdSpaceDashboard,
  MdAllInbox,
  MdOutlineAccountTree,
} from "react-icons/md";

import { routesNames } from "utils/Constants";
import { NavLink } from "react-router-dom";

import { Menu } from 'antd';
function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}
const items = [
  getItem(<NavLink to={routesNames.DASHBOARD} className={({ isActive }) => isActive ? "!text-main font-semibold" : ""}>Escritorio</NavLink >, '1', <MdHome className="h-6 w-6" />),
  getItem(<NavLink to={routesNames.REQUESTS} className={({ isActive }) => isActive ? "!text-main font-semibold" : ""}>Pedidos</NavLink >, '2', <MdOutlineShoppingCart className="h-6 w-6" />),
  getItem(<NavLink to={routesNames.PUBLICATIONS} className={({ isActive }) => isActive ? "!text-main font-semibold" : ""}>Publicaciones</NavLink >, '3a', <MdAllInbox className="h-6 w-6" />),
  // getItem('Pedidos', '2', <MdOutlineShoppingCart className="h-6 w-6" />, [
  //   getItem(<NavLink to={routesNames.PLANS_REQUEST} className={({ isActive }) => isActive ? "!text-main font-semibold" : ""}>Planes</NavLink >, 'sub21'),
  //   getItem(<NavLink to={routesNames.PUBLICATIONS} className={({ isActive }) => isActive ? "!text-main font-semibold" : ""}>Publicaciones</NavLink >, 'sub22'),
  // ]),
  getItem('Contable', '3', <MdBarChart className="h-6 w-6" />),
  // getItem('Usuarios', '4', <MdPerson className="h-6 w-6" />),
  getItem(<NavLink to={routesNames.USERS} className={({ isActive }) => isActive ? "!text-main font-semibold" : ""}>Usuarios</NavLink >, '4', <MdPerson className="h-6 w-6" />),

  getItem(<NavLink to={routesNames.ESPECIALIDADES} className={({ isActive }) => isActive ? "!text-main font-semibold" : ""}>Especialidades</NavLink >, '5', <MdOutlineAccountTree className="h-6 w-6" />),

  getItem('CMS', '6', <MdSpaceDashboard className="h-6 w-6" />, [
    getItem(<NavLink to={routesNames.FAQS} className={({ isActive }) => isActive ? "!text-main font-semibold" : ""}>Preguntas frecuentes</NavLink >, 'sub51'),
    getItem(<NavLink to={routesNames.BLOCKS} className={({ isActive }) => isActive ? "!text-main font-semibold" : ""}>Bloques y textos</NavLink>, 'sub54'),
    getItem(<NavLink to={routesNames.PAGES} className={({ isActive }) => isActive ? "!text-main font-semibold" : ""}>Páginas</NavLink>, 'sub52'),
    getItem(<NavLink to={routesNames.CONTACT} className={({ isActive }) => isActive ? "!text-main font-semibold" : ""}>Información de contacto</NavLink>, 'sub53'),
    // getItem(<NavLink to={routesNames.PLANS} className={({ isActive }) => isActive ? "!text-main font-semibold" : ""}>Planes y precios</NavLink>, 'sub55'),
  ]),
  getItem('Ajustes', '7', <MdSettings className="h-6 w-6" />, [
    getItem(<NavLink to={routesNames.SETTINGS_PUBLICATIONS} className={({ isActive }) => isActive ? "!text-main font-semibold" : ""}>Publicaciones</NavLink >, 'sub61'),
    getItem(<NavLink to={routesNames.SETTINGS_PLANS} className={({ isActive }) => isActive ? "!text-main font-semibold" : ""}>Planes</NavLink >, 'sub62'),
    getItem(<NavLink to={routesNames.TRANSFER_DATA} className={({ isActive }) => isActive ? "!text-main font-semibold" : ""}>Datos de transferencia</NavLink>, 'sub63'),
  ]),
  getItem('Notificaciones', '8', <MdNotificationsNone className="h-6 w-6" />, [
    getItem(<NavLink to={routesNames.NOTIFICATIONS_ADMIN} className={({ isActive }) => isActive ? "!text-main font-semibold" : ""}>Administrador</NavLink >, 'sub71'),
    getItem(<NavLink to={routesNames.NOTIFICATIONS_INST} className={({ isActive }) => isActive ? "!text-main font-semibold" : ""}>Institución</NavLink >, 'sub72'),
    getItem(<NavLink to={routesNames.NOTIFICATIONS_USER} className={({ isActive }) => isActive ? "!text-main font-semibold" : ""}>Postulante</NavLink >, 'sub73'),
    getItem(<NavLink to={routesNames.NOTIFICATIONS_CUSTOM} className={({ isActive }) => isActive ? "!text-main font-semibold" : ""}>Personalizadas</NavLink >, 'sub74'),
    // getItem('Option 12', '12'),
  ]),
];

// submenu keys of first level
const rootSubmenuKeys = ['6', '7', '8'];

const activeRoute = (routeName) => {
  return location.pathname.includes(routeName);
};

export function SidebarLinks(props) {
  const [openKeys, setOpenKeys] = useState(['sub1']);
  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (latestOpenKey && rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };
  return (
    <Menu
      mode="inline"
      openKeys={openKeys}
      onOpenChange={onOpenChange}
      style={{
        width: 256,
      }}
      items={items}
    />
  );
}

export default SidebarLinks;
