import { Select, Modal } from 'antd';
import Loader from 'components/loader';
import useTables from 'hooks/useTables';
import { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { BiEdit } from "react-icons/bi";
import { formatCurNumber } from "utils/Utils";
import ModalAbmVentajas from "./components/ModalAbmVentajas"

export default function PlansSettings() {

    const [isOpen, setIsOpen] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [editId, setEditId] = useState(null)
    const [ventajas, setVentajas] = useState([])
    const [ventajasIncluidas, setVentajasIncluidas] = useState([])
    const [ventajasExcluidas, setVentajasExcluidas] = useState([])
    const { register, handleSubmit, reset, formState: { errors }, setValue } = useForm();
    const { response, isLoading, fetchData, store, destroy, update } = useTables("plans")
    const { response: itemsPlans, fetchData: fetchItems, } = useTables("items-plans")

    const fetchPacks = async () => {
        const data = await fetchData(editId)
        // console.log(data)

        if (data) {
            setValue("nombre", data.nombre)
            setValue("precio", data.precio)
            setValue("precio_oferta", data.precio_oferta)
            setValue("vencimiento_oferta", data.vencimiento_oferta)
            setValue("leyenda_oferta", data.leyenda_oferta)
            setValue("descuento", data.descuento)
            setValue("descripcion", data.descripcion)
            setValue("activo", data.activo)
            setValue("meses", data.meses)
            setValue("precio_oferta_republicar", data.precio_oferta_republicar)
            setValue("precio_republicar", data.precio_republicar)

            const ventIncluidas = []
            const ventExcluidas = []

            data?.items.map((item) => {
                if (item.incluido) {
                    ventIncluidas.push(item.item_id)
                } else {
                    ventExcluidas.push(item.item_id)
                }
            })

            setVentajasIncluidas(ventIncluidas)
            setVentajasExcluidas(ventExcluidas)
        }
    }

    const fetchItemsPlans = async () => {
        const data = await fetchItems(null, true)

        const items = data.map((item) => (
            {
                value: item.id,
                label: item.nombre
            }
        ))
        setVentajas(items)

    }

    useEffect(() => {
        fetchItemsPlans()
    }, [isModalOpen])

    useEffect(() => {
        if (editId) {
            fetchPacks()
        } else {
            fetchData()
            fetchItemsPlans()

        }
    }, [editId])

    const handleSave = async (data) => {
        // console.log(data)

        data.ventajas_incluidas = ventajasIncluidas
        data.ventajas_excluidas = ventajasExcluidas

        if (editId) {
            await update(data, editId, () => {
                clear()
                fetchData()
            })
        } else {
            await store(data, () => {
                clear()
                fetchData()
            })

        }
    }

    const clear = () => {
        reset({
            'nombre': '',
            'precio': '',
            'precio_oferta': '',
            'precio_oferta_republicar': '',
            'precio_republicar': '',
            'vencimiento_oferta': '',
            'leyenda_oferta': '',
            'activo': 0,
            'descripcion': '',
            'descuento': '',
            'meses': ''
        })
        setIsOpen(false)
        setEditId(null)
        setVentajasIncluidas([])
        setVentajasExcluidas([])
    }

    return (
        <div>
            <ModalAbmVentajas isOpen={isModalOpen} setIsOpen={setIsModalOpen} />
            {/* <span className='font-semibold text-lg mt-5 block bg-white px-4 py-2 rounded-lg'>Planes cargados</span> */}

            <button className='bg-main px-4 py-1 rounded-lg' onClick={() => setIsModalOpen(true)}>ABM Ventajas</button>
            {isLoading && <Loader withWord={true} classname='mt-10' />}
            {!isLoading && response.length == 0 && <span className='block text-center mt-4 text-sm text-gray-500'>No hay planes cargados</span>}

            <div className='flex items-start gap-2'>
                <div className='flex gap-3 mt-4 flex-col w-[50%]'>
                    {!isLoading && response.length > 0 && response.map((item, idx) => {
                        return <div key={idx} className='flex flex-col justify-between items-start p-4 bg-white rounded-lg gap-4 min-w-[350px]'>
                            <div className='flex flex-col items-start py-2 px-4 bg-white gap-2 w-full relative'>
                                <span className={`${item.activo ? 'bg-green-500' : 'bg-red-500'} p-2 text-xs rounded-lg absolute block right-0 text-white`}>{item.activo ? 'ACTIVO' : 'INACTIVO'}</span>
                                <span className='font-semibold text-2xl'>{item.nombre}</span>
                                <span className='text-xs'>{item.descripcion}</span>
                                <span className='text-lg'>${formatCurNumber(item.precio / item.meses)}<span className='text-xs text-gray-600'>/mes (por {item.meses} mes/es ${formatCurNumber(item.precio)})</span></span>
                                {/* <span className='text-xs'>${formatCurNumber(item.total)} {item.off > 0 && <span className='bg-main p-1 rounded-lg'>{item.off}% off</span>}</span> */}

                                {item.precio_oferta > 0 &&
                                    <div className='bg-yellow-100 w-full flex flex-col p-2'>
                                        <span className='font-semibold text-sm'>Precio promoción <span>(- {item.off}%)</span></span>
                                        <span className='font-semibold text-sm'>${formatCurNumber(item.precio_oferta / item.meses)}<span className='text-xs text-gray-600'>/mes</span></span>
                                        {item.vencimiento_oferta && <span className='font-semibold text-sm'>Vence el {item.vencimiento_oferta}</span>}
                                    </div>
                                }

                                <div className='flex px-2 justify-between items-center w-full'>
                                    <div>
                                        <span className='mt-2 text-sm font-semibold'>Ventajas incluidas</span>
                                        <div className='flex flex-col'>
                                            {item?.items.filter(item => item.incluido == 1).map((it, idx) => {
                                                return <span className='text-xs'>- {it.item.nombre}</span>
                                            })}
                                        </div>
                                    </div>

                                    <div>
                                        <span className='mt-2 text-sm font-semibold'>Ventajas excluidas</span>
                                        <div className='flex flex-col'>
                                            {item?.items.filter(item => item.incluido == 0).map((it, idx) => {
                                                return <span className='text-xs'>- {it.item.nombre}</span>
                                            })}
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className='flex items-start justify-between gap-2 px-4 w-full'>
                                <button
                                    className="text-xs flex gap-2 text-gray-500 bg-transparent hover:outline-none active:outline-0"
                                    onClick={() => { setEditId(item.id); setIsOpen(true) }}>
                                    <BiEdit className="text-lg" />Editar</button>
                                {/* <Popconfirm
                                    title="Eliminar"
                                    description="¿Está seguro que desea eliminar el registro?"
                                    onConfirm={() => destroy(item.id)}
                                    okText="Si, Eliminar"
                                    cancelText="No"
                                    okButtonProps={{
                                        className: "bg-red-500 hover:bg-red-400"
                                    }}
                                >
                                    <button
                                        className={`${item.id == 1 && 'hidden'} text-xs flex gap-2 text-gray-500 bg-transparent hover:outline-none active:outline-0`}
                                    >
                                        <BiTrash className="text-lg" />
                                        Borrar
                                    </button>
                                </Popconfirm> */}
                            </div>
                        </div>
                    })}
                </div>
                <div className='w-[50%]'>

                    <div className="mt-4 flex flex-col items-start">
                        {!isOpen && <button onClick={() => setIsOpen(true)} className='bg-main px-5 py-2 rounded-lg text-sm font-semibold'>+ Agregar nuevo plan</button>}
                    </div>

                    <div className={(isOpen ? '' : 'hidden') + ' bg-white rounded-lg p-4'}>
                        <div className='flex flex-col items-start justify-center'>
                            <label className='text-sm font-semibold mb-1'>Nombre</label>
                            <input
                                {...register("nombre", { required: true })}
                                className='border px-4 py-1 rounded-lg w-full text-sm'
                            />
                            {errors.nombre && <span className='text-sm text-red-600'>El nombre es obligatorio</span>}
                        </div>

                        <div className='flex flex-col items-start justify-center mt-4'>
                            <label className='text-sm font-semibold mb-1'>Breve descripción</label>
                            <textarea
                                {...register("descripcion")}
                                className='border px-4 py-1 rounded-lg w-full text-sm'
                            ></textarea>
                        </div>

                        <div className='flex gap-2 items-center justify-start mt-4 w-full'>

                            <div className='flex flex-col items-start justify-center w-full'>
                                <label className='text-sm font-semibold mb-1'>Precio <span className='text-xs font-normal'>(Total meses)</span></label>
                                <input
                                    type='number'
                                    {...register("precio", { required: true })}
                                    className='border px-4 py-1 rounded-lg w-full text-sm'
                                />
                                {errors.precio && <span className='text-sm text-red-600'>El precio es obligatorio</span>}
                            </div>

                            <div className='flex flex-col items-start justify-center w-full'>
                                <label className='text-sm font-semibold mb-1'>Meses</label>
                                <input
                                    type='number'
                                    {...register("meses")}
                                    className='border px-4 py-1 rounded-lg w-full text-sm'
                                />
                                {/* {errors.meses && <span className='text-sm text-red-600'>El precio es obligatorio</span>} */}
                            </div>
                        </div>

                        <div className='flex gap-2 items-center justify-start mt-4 w-full'>

                            <div className='flex flex-col mt-4 items-start justify-center w-full'>
                                <label className='text-sm font-semibold mb-1'>Precio republicar <span className='text-xs font-normal'>(Total meses)</span></label>
                                <input
                                    type='number'
                                    {...register("precio_republicar")}
                                    className='border px-4 py-1 rounded-lg w-full text-sm'
                                />
                            </div>

                            <div className='flex flex-col mt-4 items-start justify-center w-full'>
                                <label className='text-sm font-semibold mb-1'>% Descuento en publicaciones</label>
                                <input
                                    type='number'
                                    {...register("descuento")}
                                    className='border px-4 py-1 rounded-lg w-full text-sm'
                                />
                            </div>
                        </div>

                        <span className=' font-bold mt-6 block border-b mb-4'>Oferta</span>

                        <div className='flex gap-2 items-center justify-start w-full'>

                            <div className='flex flex-col items-start justify-center w-full'>
                                <label className='text-sm font-semibold mb-1'>Precio oferta <span className='text-xs font-normal'>(Total meses)</span></label>
                                <input
                                    type='number'
                                    {...register("precio_oferta")}
                                    className='border px-4 py-1 rounded-lg w-full text-sm'
                                />
                            </div>

                            <div className='flex flex-col items-start justify-center w-full'>
                                <label className='text-sm font-semibold mb-1'>Fecha limite</label>
                                <input
                                    type='date'
                                    {...register("vencimiento_oferta")}
                                    className='border px-4 py-1 rounded-lg w-full text-sm'
                                />
                            </div>
                        </div>

                        <div className='flex flex-col mt-4 items-start justify-center w-[50%]'>
                            <label className='text-sm font-semibold mb-1'>Precio oferta republicar <span className='text-xs font-normal'>(Total meses)</span></label>
                            <input
                                type='number'
                                {...register("precio_oferta_republicar")}
                                className='border px-4 py-1 rounded-lg w-full text-sm'
                            />
                        </div>
                        {/* <div className='flex flex-col items-start justify-center w-full mt-2'>
                            <label className='text-sm font-semibold mb-1'>Leyenda oferta</label>
                            <textarea
                                {...register("leyenda_oferta")}
                                className='border px-4 py-1 rounded-lg w-full text-sm'
                            ></textarea>
                        </div> */}

                        <div className='flex flex-col mt-6'>
                            <label className='text-sm font-semibold mb-1'>Ventajas incluidas <span className='text-xs font-normal text-green-700'>(Se mostraran con tilde verde)</span></label>
                            <Select
                                mode="multiple"
                                // size={size}
                                value={ventajasIncluidas}
                                placeholder="Seleccionar"
                                // defaultValue={ventajasIncluidas}
                                onChange={(value) => {
                                    setVentajasIncluidas(value)
                                }}
                                style={{
                                    width: '100%',
                                }}
                                options={ventajas}
                            />
                        </div>

                        <div className='flex flex-col mt-4'>
                            <label className='text-sm font-semibold mb-1'>Ventajas excluidas <span className='text-xs font-normal text-red-700'>(Se mostraran con cruz roja)</span></label>
                            <Select
                                mode="multiple"
                                // size={size}
                                placeholder="Seleccionar"
                                value={ventajasExcluidas}
                                onChange={(value) => {
                                    setVentajasExcluidas(value)
                                }}
                                style={{
                                    width: '100%',
                                }}
                                options={ventajas}
                            />
                        </div>

                        <div className='flex flex-row mt-4 items-center gap-2'>
                            <input
                                type='checkbox'
                                {...register("activo")}
                                className='border px-4 py-1'
                                id="activo"
                            />
                            <label htmlFor='activo' className='text-sm font-semibold'>Activo</label>
                        </div>


                        <div className='flex items-end justify-end gap-3 mt-3'>
                            <button onClick={() => {
                                clear()
                            }} className='bg-gray-50 text-sm px-6 font-bold py-2 rounded-lg hover:opacity-80'>Cancelar</button>
                            <button onClick={handleSubmit(handleSave)} className='text-sm px-6 bg-main font-bold py-2 rounded-lg hover:opacity-80'>Guardar</button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}
