import { Popconfirm } from 'antd';
import Loader from 'components/loader';
import useTables from 'hooks/useTables';
import { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import { BiEdit, BiTrash } from "react-icons/bi";

export default function Faqs() {
    const [isOpen, setIsOpen] = useState(false)
    const [editId, setEditId] = useState(null)
    const { register, handleSubmit, reset, formState: { errors }, setValue } = useForm();
    const { response, isLoading, fetchData, store, destroy, update } = useTables("faqs")

    const fetchFaq = async () => {
        const data = await fetchData(editId)

        if (data) {
            setValue("pregunta", data.pregunta)
            setValue("respuesta", data.respuesta)
        }
    }

    useEffect(() => {
        if (editId) {
            fetchFaq()
        } else {
            fetchData()
        }
    }, [editId])

    const handleSave = async (data) => {

        if (editId) {
            await update(data, editId, () => {
                setIsOpen(false)
                setEditId(null)
                reset({ pregunta: '', respuesta: '' })
            })
        } else {
            await store(data, () => {
                // console.log("PASO")
                reset({ pregunta: '', respuesta: '' })
                setIsOpen(false)
                fetchData()
            })

        }
    }

    return (
        <div>
            <div className="mt-3 flex flex-col items-start">
                {!isOpen && <button onClick={() => setIsOpen(true)} className='bg-main px-5 py-2 rounded-lg text-sm font-semibold'>+ Agregar nueva pregunta</button>}
            </div>

            <div className={isOpen ? '' : 'hidden'}>
                <div className='flex flex-col items-start justify-center'>
                    <label className='text-sm font-semibold mb-1'>Pregunta</label>
                    <input
                        {...register("pregunta", { required: true })}
                        className='border px-4 py-1 rounded-lg w-full'
                    />
                    {errors.pregunta && <span className='text-sm text-red-600'>La pregunta es obligatoria</span>}
                </div>

                <div className='flex flex-col items-start justify-center mt-4'>
                    <label className='text-sm font-semibold mb-1'>Respuesta</label>
                    <textarea
                        {...register("respuesta", { required: true })}
                        className='border px-4 py-1 rounded-lg w-full min-h-[200px]'
                    ></textarea>
                    {errors.respuesta && <span className='text-sm text-red-600'>La respuesta es obligatoria</span>}

                </div>

                <div className='flex items-end justify-end gap-3 mt-3'>
                    <button onClick={() => {
                        setIsOpen(false)
                        setEditId(null)
                        reset()
                    }} className='bg-gray-50 text-sm px-6 font-bold py-2 rounded-lg hover:opacity-80'>Cancelar</button>
                    <button onClick={handleSubmit(handleSave)} className='text-sm px-6 bg-main font-bold py-2 rounded-lg hover:opacity-80'>Guardar</button>
                </div>
            </div>

            <span className='font-semibold text-lg mt-5 block bg-white px-4 py-2 rounded-lg'>Preguntas cargadas</span>

            {!isLoading && response.length == 0 && <span className='block text-center mt-4 text-sm text-gray-500'>No hay preguntas frecuentes cargadas</span>}
            {isLoading && <Loader withWord={true} classname='mt-10' />}
            <div className='flex flex-col gap-2 mt-4'>
                {!isLoading && response.length > 0 && response.map((item, idx) => {
                    return <div key={idx} className='flex justify-between items-center py-2 px-4 bg-white rounded-lg gap-4'>
                        <div className='flex flex-col items-start py-2 px-4 bg-white gap-2'>
                            <span className='font-semibold'>{item.pregunta}</span>
                            <span className='text-sm'>{item.respuesta}</span>
                        </div>

                        <div className='flex flex-col items-start justify-center gap-2 px-4'>
                            <button
                                className="text-xs flex gap-2 text-gray-500 bg-transparent hover:outline-none active:outline-0"
                                onClick={() => { setEditId(item.id); setIsOpen(true) }}>
                                <BiEdit className="text-lg" />Editar</button>
                            <Popconfirm
                                title="Eliminar"
                                description="¿Está seguro que desea eliminar el registro?"
                                onConfirm={() => destroy(item.id)}
                                okText="Si, Eliminar"
                                cancelText="No"
                                okButtonProps={{
                                    className: "bg-red-500 hover:bg-red-400"
                                }}
                            >
                                <button
                                    className="text-xs flex gap-2 text-gray-500 bg-transparent hover:outline-none active:outline-0"
                                >
                                    <BiTrash className="text-lg" />
                                    Borrar
                                </button>
                            </Popconfirm>
                        </div>
                    </div>
                })}
            </div>
        </div>
    )
}
