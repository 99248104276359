import useSpecialties from "hooks/useSpecialties";
import { useEffect, useState } from "react";
import TableSpecialties from "views/admin/specialties/components/TableSpecialties";
import InputSpecialties from "views/admin/specialties/components/InputSpecialties";

const Specialties = () => {
  const { response, isLoading, error, fetchData } = useSpecialties();
  const [editData, setEditData] = useState(null);  // Estado para la especialidad que se va a editar

  useEffect(() => {
    fetchData();
  }, []);

  // Función para manejar la especialidad seleccionada desde TableSpecialties
  const handleEdit = (dataEdit) => {
    setEditData(dataEdit);
  };

  return (
    <div className="flex items-start justify-between sm:gap-4 sm:flex-row flex-col">
      <InputSpecialties specialties={response} isLoading={isLoading} editData={editData} />
      <TableSpecialties isLoading={isLoading} error={error} data={response} onEdit={handleEdit} />
    </div>
  );
};

export default Specialties;
