import { useEffect, useState } from 'react';
import { TreeSelect } from 'antd';

const buildTreeData = (abilities) => {
    const tree = [];
    const map = {};

    abilities.forEach((ability) => {
        map[ability.id] = {
            title: ability.especialidad,
            value: ability.id,
            key: ability.id,
            children: [],
            disabled: false,
        };
    });

    abilities.forEach((ability) => {
        if (ability.parent_id === null) {
            tree.push(map[ability.id]);
        } else if (map[ability.parent_id]) {
            map[ability.parent_id].children.push(map[ability.id]);
            map[ability.parent_id].disabled = true;
        }
    });

    return tree;
};

const AbilitiesTreeSelect = ({ abilities, onChange, selectedValue }) => {
    const [value, setValue] = useState()
    const [treeData, setTreeData] = useState([])

    useEffect(() => {
        if (abilities.length > 0) {
            const data = buildTreeData(abilities)
            setTreeData(data)
        }
    }, [abilities])

    useEffect(() => {
        if(selectedValue == null){
            setValue(null)
        }

        if (selectedValue) {
            setValue(selectedValue)
        }
    }, [selectedValue])

    const handleChange = (newValue) => {
        setValue(newValue)
        onChange(newValue)
    }

    return (
        <TreeSelect
            style={{ width: '100%' }}
            value={value || selectedValue}
            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
            treeData={treeData}
            placeholder="Seleccionar especialidades"
            treeDefaultExpandAll
            onChange={handleChange}
        />
    );
};

export default AbilitiesTreeSelect;
