export const BASE_URL = process.env.REACT_APP_BASE_URL
export const SITE_URL = process.env.REACT_APP_SITE_URL
export const APIURI = process.env.REACT_APP_APIURI
export const PUBLIC_URI_IMAGES =  process.env.REACT_APP_PUBLIC_URI_IMAGES
export const BASE_API = process.env.REACT_APP_BASE_API

// export const BASE_URL = 'http://localhost:3000/'
// export const SITE_URL = 'http://localhost:5173/'
// export const APIURI = 'http://localhost:8000/api/'
// export const BASE_API = 'http://localhost:8000/'
// export const PUBLIC_URI_IMAGES = 'http://localhost:8000/'

export const getCountry = () => {
    const country = localStorage.getItem("country")
    return country
    // let host = window.location.host
    // const dominio = host.split('.')
    // return 'ar' //dominio[0]

}