import { HttpGet, HttpPost, HttpPostImage, HttpPut, HttpDelete } from "./Http"


export const getStats = async (uri) => {
    try {
        const data = await HttpGet(`admin/stats/${uri}`)
        return data;
    } catch (error) {
        return error
    }
}