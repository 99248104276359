import { DatePicker } from "antd";
import { Controller } from "react-hook-form";

export default function InputUseForm({ register, name, errors, placeholder, label, control = null, type = "text", className = "", classNameInput = "", picker = "date", rules = {}, disabled = false, format = "DD/MM/YYYY" }) {

    return (
        <div className={className}>
            {label && <label className="text-start block font-semibold mb-2 mt-5">{label} {rules.required && <span className="text-red-500">*</span>}</label>}
            {type == 'date' ? (
                <Controller
                    name={name}
                    control={control}
                    rules={rules}
                    render={({ field }) =>
                        <DatePicker
                            {...field}
                            format={format}
                            className={"disabled:opacity-25 text-sm rounded-md border-0 w-full p-4 mb-2 bg-gray-50 " + (errors[`${name}`] && " border-red-500")}
                            placeholder={placeholder}
                            picker={picker}
                            disabled={disabled}
                        />
                    }
                />
            ) : (
                <input
                    disabled={disabled}
                    {...register(name, rules)}
                    type={type}
                    className={classNameInput + " disabled:opacity-25 text-sm rounded-md  w-full p-4 mb-2 bg-gray-50 " + (errors[`${name}`] && " border-red-500")}
                    placeholder={placeholder}
                />
            )}
            {errors[`${name}`] && <div className="text-start"><span className="text-red-500 text-sm italic text-start">{errors[`${name}`].message}</span></div>}

        </div>
    )
}
