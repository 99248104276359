import { HttpGet, HttpPost, HttpPostImage, HttpPut, HttpDelete } from "./Http"


export const getRequestPending = async (filters = {}) => {
    try {
        const data = await HttpPost(`admin/pedidos`, JSON.stringify(filters))
        return data;
    } catch (error) {
        return error
    }
}

export const getRequestPlan = async (id) => {
    try {
        const data = await HttpGet(`admin/pedidos/${id}`, false)
        return data;
    } catch (error) {
        return error
    }
}

export const setConfirmPay = async (userId) => {
    try {
        const data = await HttpGet(`admin/plan/confirmar/${userId}`, false)
        return data;
    } catch (error) {
        return error
    }
}

export const setStatusRequest = async (id, payload) => {
    try {
        const data = await HttpPost(`admin/pedidos/${id}/estado`, JSON.stringify(payload))
        return data;
    } catch (error) {
        return error
    }
}