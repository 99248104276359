import { notification } from 'antd';


const useNotification = () => {
    const [api, contextHolder] = notification.useNotification();

    const openNotification = (description, type = 'info', title = 'Alerta', placement = "topRight") => {
        if (type == 'info') {
            api.info({ message: title, description: description, placement, });
        } else if (type == 'success') {
            api.success({ message: title, description: description, placement, });
        }
    };

    return { contextHolder, openNotification }
}


export default useNotification