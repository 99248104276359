
// import { useAuth } from '@hooks/useAuth';
import { getField, setField } from "services/Configuracion";
import { useEffect, useState } from "react";


const useConfiguracion = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [response, setResponse] = useState([])
    const [error, setError] = useState(null)


    const fetchCampo = async (campo) => {
        setIsLoading(true)
        const data = await getField(campo)

        setIsLoading(false)
        return data
    }

    const storeCampo = async (campo, valor) => {
        const payload = {
            campo,
            valor
        }
        const data = await setField(payload)
        return data
    }

    // Ok
    // const fetchData = async (id = null, withReturn = false) => {
    //     setError(null)
    //     setIsLoading(true)

    //     try {
    //         let data;

    //         if (id) {
    //             // data = await getPublication(id)
    //             setIsLoading(false)
    //         } else {
    //             data = await getRequestPending()
    //         }

    //         if (withReturn) {
    //             setIsLoading(false)
    //             return id ? data[0] : data
    //         }

    //         setResponse(id ? data[0] : data)
    //         setIsLoading(false)
    //     } catch (error) {
    //         console.log(error)
    //         setError(error)
    //     } finally {
    //         setIsLoading(false)
    //     }

    // }



    return { response, isLoading, error, fetchCampo, storeCampo }
}


export default useConfiguracion