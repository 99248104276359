import Card from "components/card";
import usePublications from "hooks/usePublications";
import { Link } from "react-router-dom";
import { routesNames } from "utils/Constants";
import { getFullDay, getFullMonth } from "utils/Utils";

import Loader from "components/loader";
import { useEffect } from "react";
import { MdCancel, MdCheckCircle, MdOutlineError } from "react-icons/md";

const tableHeaders = ['NOMBRE', 'ESTADO', 'FECHA']

const ComplexTable = () => {

  const { isLoading, response, fetchData } = usePublications()

  const fetchPublications = async () => {
    await fetchData()
  }

  useEffect(() => {
    fetchPublications()
  }, [])


  if (isLoading) {
    return <Loader />
  }

  return (
    <Card extra={"w-full h-full px-6 pb-6 sm:overflow-x-auto"}>
      <div className="relative flex items-center justify-between pt-4">
        <div className="text-xl font-bold text-navy-700 dark:text-white">
          Últimas publicaciones
        </div>
        <Link
          to={routesNames.PUBLICATIONS}
          className={`text-sm hover:cursor-pointer bg-lightPrimary p-2 text-brand-500 hover:bg-gray-100 dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/10 linear justify-center rounded-lg font-semibold transition duration-200`}
        >
          Ver todas
        </Link>
      </div>

      <div className="mt-8 overflow-x-scroll xl:overflow-hidden">
        <table className="w-full">
          <thead>
            <tr>
              {tableHeaders.map((header, idx) => (
                <th
                  key={idx}
                  className="border-b border-gray-200 pr-28 pb-[10px] text-start dark:!border-navy-700"
                >
                  <p className="text-xs tracking-wide text-gray-600">
                    {header}
                  </p>
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {response?.data?.length > 0 && (
              response.data.slice(0, 5).map((pub, idx) => {
                const date = new Date(pub.created_at);
                return <tr key={idx}>
                  <td
                    className="pt-[14px] pb-[10px] sm:text-[14px]"
                  >
                    <Link to={`${routesNames.PUBLICATION}/${pub.id}`} className="text-sm font-semibold text-blue-800 dark:text-white">
                      {pub.nombre}
                    </Link>
                  </td>

                  <td
                    className="pt-[14px] pb-[10px] sm:text-[14px]"
                  >
                    <div className="flex items-center gap-2">
                      <div className={`rounded-full text-xl`}>
                        {pub?.estado?.estado?.nombre === "Aprobado" ? (
                          <MdCheckCircle className="text-green-500" />
                        ) : pub?.estado?.estado?.nombre === "Vencido" || pub?.estado?.estado?.nombre === "Cancelado" ? (
                          <MdCancel className="text-red-500" />
                        ) : pub?.estado?.estado?.nombre === "Pendiente aprobación" || pub?.estado?.estado?.nombre === "Pendiente de Pago" ? (
                          <MdOutlineError className="text-orange-500" />
                        ) : null}
                      </div>
                      <p className="text-sm font-bold text-navy-700 dark:text-white">
                        {pub?.estado?.estado?.nombre}
                      </p>
                    </div>
                  </td>

                  <td
                    className="pt-[14px] pb-[10px] sm:text-[14px]"
                  >
                    <p className="text-sm font-bold text-navy-700 dark:text-white">
                      {getFullDay(date, true)}/{getFullMonth(date)}/{date.getFullYear()}
                    </p>
                  </td>
                </tr>
              })
            )}
          </tbody>


        </table>
      </div>
    </Card>
  );
};

export default ComplexTable;
