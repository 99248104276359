import MiniCalendar from "components/calendar/MiniCalendar";
import WeeklyRevenue from "views/admin/default/components/WeeklyRevenue";
import TotalSpent from "views/admin/default/components/TotalSpent";
import PieChartCard from "views/admin/default/components/PieChartCard";
import { IoMdHome } from "react-icons/io";
import { IoDocuments } from "react-icons/io5";
import { MdBarChart, MdDashboard } from "react-icons/md";

import { columnsDataCheck, columnsDataComplex } from "./variables/columnsData";

import Widget from "components/widget/Widget";
import CheckTable from "views/admin/default/components/CheckTable";
import ComplexTable from "views/admin/default/components/ComplexTable";
import DailyTraffic from "views/admin/default/components/DailyTraffic";
import TaskCard from "views/admin/default/components/TaskCard";
import tableDataCheck from "./variables/tableDataCheck.json";
import tableDataComplex from "./variables/tableDataComplex.json";
import useStats from "hooks/useStats";
import { useEffect, useState } from "react";
import Loader from "components/loader";


const Dashboard = () => {
  const { isLoading, fetchData } = useStats('main')
  const [stats, setStats] = useState()

  const fetchStats = async () => {
    const data = await fetchData()
    setStats(data)

  }
  useEffect(() => {
    fetchStats()
  }, [])

  return (

    <div>
      {/* Card widget */}

      <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6">
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"Earnings"}
          subtitle={"$340.5"}
        />
        <Widget
          icon={<IoDocuments className="h-6 w-6" />}
          title={"Spend this month"}
          subtitle={"$642.39"}
        />
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"Sales"}
          subtitle={"$574.34"}
        />

        <Widget
          icon={<MdDashboard className="h-6 w-6" />}
          title={"Publicaciones activas"}
          subtitle={isLoading ? <Loader small={true} /> : stats?.publicaciones}
        />
        <Widget
          icon={<MdBarChart className="h-7 w-7" />}
          title={"Postulantes"}
          subtitle={isLoading ? <Loader small={true} /> : stats?.postulantes}

        />
        <Widget
          icon={<IoMdHome className="h-6 w-6" />}
          title={"Instituciones"}
          subtitle={isLoading ? <Loader small={true} /> : stats?.instituciones}

        />
      </div>

      {/* Charts */}

      <div className="mt-5 grid grid-cols-1 gap-5 md:grid-cols-2">
        <TotalSpent />
        <WeeklyRevenue />
      </div>

      {/* Tables & Charts */}

      <div className="mt-5 grid grid-cols-1 gap-5">
        <ComplexTable />
      </div>

      <div className="mt-5 grid grid-cols-1 gap-5 xl:grid-cols-2">
        {/* Check Table */}


        {/* Traffic chart & Pie Chart */}

        <div className="grid grid-cols-1 gap-5 rounded-[20px] md:grid-cols-2">
          <DailyTraffic />
          <MiniCalendar />
        </div>
        <PieChartCard />

        {/* Complex Table , Task & Calendar */}

        {/* <ComplexTable /> */}

        {/* Task chart & Calendar */}

        {/* <div className="grid grid-cols-1 gap-5 rounded-[20px] md:grid-cols-2">
          <TaskCard />
          <div className="grid grid-cols-1 rounded-[20px]">
            <MiniCalendar />
          </div>
        </div> */}
      </div>

      <div className="mt-5 grid grid-cols-1 gap-5">

        <CheckTable />
      </div>


    </div>
  );
};

export default Dashboard;
