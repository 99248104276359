// import { useAuth } from '@hooks/useAuth';
import { getPublicationsByState, getPostulationsByPublication, getPostulatedsAll, getPublication, setStatusPublication, storePublication, setConfirmPay, getLocationsAccount } from "services/Publications";
import { useState } from "react";


const usePublications = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [response, setResponse] = useState([])
    const [error, setError] = useState(null)


    // Ok
    const fetchData = async (id = null, payload = {}, withReturn = false) => {
        setError(null)
        setIsLoading(true)

        try {
            let data;

            if (id) {
                data = await getPublication(id)
                setIsLoading(false)
            } else {
                data = await getPublicationsByState(payload)
            }

            if (withReturn) {
                setResponse(data.data)
                setIsLoading(false)
                return id ? data : data
            }
            // console.log("PASO", data, id)
            setResponse(id ? data : data)
            setIsLoading(false)
        } catch (error) {
            console.log(error)
            setError(error)
        } finally {
            setIsLoading(false)
        }

    }

    const changeStatus = async (publicationId, payload) => {
        // console.log(payload)
        const data = await setStatusPublication(publicationId, payload)
        return data
    }

    const confirmPay = async (publicationId) => {
        // console.log(payload)
        const data = await setConfirmPay(publicationId)
        return data
    }

    const save = async (payload, onSuccess, editId = null) => {

        setError(null)
        setIsLoading(true)
        try {
            const data = await storePublication(payload, editId)

            if (data.error) {
                setError(data.data[0])
            } else {
                // fetch()
                onSuccess(data)
            }

            setIsLoading(false)
        } catch (error) {
            console.log(error)
            setError(error)
        } finally {
            setIsLoading(false)
        }

    }

    const store = (payload, onSuccess, editId = null) => {
        save(payload, onSuccess, editId)
    }

    const fetchLocationsByAccount = async (userId) => {

        const data = await getLocationsAccount(userId)
        return data
    }

    const getPostulateds = async (id = null, limit = 5) => {
        let data;
        setIsLoading(true)

        if (id) {
            data = await getPostulationsByPublication(id)
        } else {
            data = await getPostulatedsAll(limit)
        }
        setResponse(data)
        setIsLoading(false)
    }



    return { response, isLoading, error, getPostulateds, fetchData, changeStatus, confirmPay, store, fetchLocationsByAccount }
}


export default usePublications