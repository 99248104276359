import { Table } from "antd";
import { SITE_URL } from "config/config";
import usePublications from "hooks/usePublications";
import { getCurriculumPDF } from "services/Usuarios"
import { FaFilePdf } from "react-icons/fa";
import { useEffect } from "react";
import { getFullDay, getFullMonth } from "utils/Utils";
import { Link } from "react-router-dom";
import { routesNames } from "utils/Constants";

import { BASE_API } from "config/config"
import useNotification from 'hooks/useNotification';



export default function PostulationsTable() {
    const { isLoading, response, getPostulateds } = usePublications()
    const { contextHolder, openNotification } = useNotification()

    const columns = [
        {
            title: 'Postulante',
            dataIndex: 'cuenta_id',
            key: 'nombre',
            render: (text, record) => <Link to={`${routesNames.USER}/${record?.user_id}`} className="text-blue-700">{record.postulante?.nombre} {record.postulante?.apellido}</Link>,
        },
        {
            title: 'Institución',
            dataIndex: 'cuenta_id',
            key: 'nombre',
            render: (text, record) => <Link to={`${routesNames.USER}/${record.publicacion?.cuenta?.user_id}`} className="text-blue-700">{record.publicacion?.cuenta?.razon_social}</Link>,
        },
        {
            title: 'Publicación',
            dataIndex: 'publicacion',
            key: 'publicacion',
            render: (text, record) => <Link to={`${routesNames.PUBLICATION}/${record.publicacion?.id}`}>{record.publicacion?.nombre}</Link>,
        },
        {
            title: 'Fecha',
            dataIndex: 'created_at',
            render: (text) => {
                const date = new Date(text);
                return `${getFullDay(date, true)}/${getFullMonth(date)}/${date.getFullYear()}`
            }
        },
        {
            title: 'CV',
            dataIndex: 'created_at',
            render: (text, record) => {
                return <div className="flex items-center gap-1">
                    <a target='_blank' rel='noopener noreferrer' href={`${SITE_URL}cv/${record.postulante?.uuid}`} className='bg-main text-xs text-white px-2 rounded-md py-1'>Ver curriculum</a>

                    <a onClick={() => fetchPdf(record?.user_id)} className='bg-red-500 text-xs text-white px-2 rounded-md py-1 flex items-center'><FaFilePdf className='text-white' /> Descargar</a>
                </div>

            }
        },
    ];

    const fetchPdf = async (userId) => {
        const data = await getCurriculumPDF(userId)

        if (data?.uuid) {
            window.open(`${BASE_API}cv/${data.uuid}.pdf`, "_blank", "noreferrer");
        } else {
            openNotification('El curriculum se encuentra incompleto', 'success', 'Atención!')
        }
    }

    useEffect(() => {
        getPostulateds(null, 100)
    }, [])

    return (
        <div className="flex flex-col items-end gap-2 w-full    ">
            {contextHolder}

            {/* <button className='bg-red-500 text-white flex gap-2 items-center mb-2 px-3 py-1 rounded-lg'><FaFilePdf className='text-white' /> Descargar CV de todos los postulantes</button> */}
            <Table
                className="w-full"
                loading={isLoading}
                columns={columns}
                dataSource={response}
                rowKey={(record) => record?.id}
                pagination={{
                    pageSize: 15,
                    showSizeChanger: false,

                }}
                size="small"
                locale={{
                    emptyText: "No hay postulaciones"
                }}
            />
        </div>
    )
}
