import { HttpGet, HttpPost } from "./Http"

export const login = async (email, password, type) => {

    const payload = {
        email,
        password,
        type
    }

    const data = await HttpPost('login', JSON.stringify(payload), false)
    return data;

}

export const registerService = async (payload) => {


    const data = await HttpPost('register', JSON.stringify(payload), false)

    // console.log(data)
    return data;

}