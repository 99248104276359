import useBloques from 'hooks/useBloques'
import { Collapse } from 'antd';
import { useForm } from "react-hook-form";
import Loader from 'components/loader';

export default function Blocks() {

    const { response, isLoading, store, fetchAll } = useBloques()
    const { register, handleSubmit, reset, formState: { errors: err }, setValue } = useForm();

    // console.log(response)

    const getItems = () => {

        const blocks = []
        response?.map((item, idx) => {

            setValue(`bloque_${item.name}`, parseInt(item.active))
            blocks.push({
                key: idx,
                label: item.title,
                children: <div className='flex flex-col gap-3'>
                    <div className='flex items-center gap-2 '>
                        <input {...register(`bloque_${item.name}`)} type="checkbox" id={`check_${item.name}`} value="" />
                        <span htmlFor={`check_${item.name}`}>Mostrar</span>
                    </div>
                    {item?.textos?.map((text, idxx) => {
                        setValue(text.key, text.value)

                        return <div key={idxx} className='flex flex-col items-start justify-center w-full'>
                            <label className='text-sm font-semibold mb-1'>Texto {idxx + 1}</label>
                            <input
                                {...register(`${text.key}`)}
                                className='border px-4 py-1 rounded-lg w-full'
                            />
                            {/* {err.titulo && <span className='text-sm text-red-600'>El título es obligatorio</span>} */}
                        </div>
                    })}


                </div>,
            })
        })

        return blocks
    }

    const onSubmit = async (data) => {
        await store(data, (res) => {
            // console.log(res)
            fetchAll()
        })

    }

    if (isLoading) {
        return <Loader />
    }

    return (
        <div>
            <button onClick={handleSubmit(onSubmit)} className='text-sm bg-aprobado px-5 py-1 mb-2 rounded-md'>Grabar datos</button>
            <Collapse
                items={getItems()}
            />
        </div>
    )
}
