import React from 'react'

export default function CMS() {
    return (
        <div>
            <div className="mt-3 flex flex-col items-start">
                <span>Preguntas frecuentes</span>

                <button className='bg-main px-2 py-1 rounded-lg text-sm'>+ Nueva pregunta</button>
            </div>

            <div>
                <div className='flex flex-col items-start justify-center'>
                    <label>Pregunta</label>
                    <input className='border px-4 py-1 rounded-lg w-full' />
                </div>

                <div className='flex flex-col items-start justify-center'>
                    <label>Respuesta</label>
                    <textarea className='border px-4 py-1 rounded-lg w-full min-h-[200px]'></textarea>
                </div>

                <div className='flex items-end justify-end gap-3 mt-3'>
                    <button className='bg-gray-50 text-sm px-6 font-bold py-2 rounded-lg hover:opacity-80'>Cancelar</button>
                    <button className='text-sm px-6 bg-main font-bold py-2 rounded-lg hover:opacity-80'>Guardar</button>
                </div>
            </div>
        </div>
    )
}
