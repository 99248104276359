import SelectUseForm from "components/SelectUseForm";
import Checkbox from "components/checkbox";
import InputField from "components/fields/InputField";
import Footer from "components/footer/FooterAuthDefault";
import { useAuth } from "hooks/useAuth";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { login as tryLogin } from "services/Auth";
import { routesNames } from "utils/Constants";

export default function SignIn() {
  const { login } = useAuth();
  const { register, handleSubmit, control, formState: { errors } } = useForm();
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  document.documentElement.dir = "ltr";

  const onSubmit = async (data) => {

    // console.log(data);
    // return;
    setError(null)
    setIsLoading(true)
    localStorage.setItem('country', data.pais)

    const response = await tryLogin(data.email, data.password, "A")

    // console.log(response)
    if (response.error) {
      if (response.data.length > 0) {
        setError(response.data[0])
      } else {
        setError(response.message)
      }

      setIsLoading(false)

      return;
    }

    login({
      ...response.data,
      email: data.email,

    })
    setIsLoading(false)

    navigate(`${routesNames.DASHBOARD}`);

  }

  return (
    <div>
      <div className="relative float-right h-full min-h-screen w-full !bg-white dark:!bg-navy-900">
        {/* <FixedPlugin /> */}
        <form onSubmit={handleSubmit(onSubmit)} className={`mx-auto min-h-screen`}>
          <div className="relative flex">
            <div className="mx-auto flex min-h-full w-full flex-col justify-start pt-12 md:max-w-[75%] lg:h-screen lg:max-w-[1013px] lg:px-8 lg:pt-0 xl:h-[100vh] xl:max-w-[1383px] xl:px-0 xl:pl-[70px]">
              <div className="mb-auto flex flex-col pl-5 pr-5 md:pr-0 md:pl-12 lg:max-w-[48%] lg:pl-0 xl:max-w-full">
                <div className="mt-16 mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-center">
                  {/* Sign in section */}
                  <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
                    <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
                      Ingresar
                    </h4>
                    <p className="mb-9 ml-1 text-base text-gray-600">
                      Ingresar como administrador en Pickapply!
                    </p>


                    <InputField
                      rules={{ required: "El email es requerido" }}
                      register={register}
                      name="email"
                      variant="auth"
                      extra="mb-3"
                      label="Email*"
                      placeholder="mail@pickapply.com"
                      id="email"
                      type="text"
                      errors={errors}
                    />

                    <InputField
                      rules={{ required: "El password es requerido", minLength: { value: 8, message: "El password debe contener 8 o más caracteres" } }}
                      register={register}
                      name="password"
                      variant="auth"
                      extra="mb-3"
                      label="Password*"
                      placeholder="Min. 8 carácteres"
                      id="password"
                      type="password"
                      errors={errors}

                    />

                    <label className="ml-1.5 font-medium text-start block mb-1 mt-3">Pais *</label>
                    <select
                      {...register("pais", { required: "Debe seleccionar el pais" })}
                      className={" disabled:opacity-25 text-sm p-2  w-full mb-2 rounded-xl border bg-white/0 py-4 px-4" + (errors.pais && " border-red-500")}
                      placeholder={"Seleccione un pais"}
                    >
                      <option value="">Seleccionar</option>
                      <option value="ar">Argentina</option>
                      <option value="uy">Uruguay</option>
                    </select>
                    {errors.pais && <div className="text-start"><span className="text-red-500 text-sm italic text-start">{errors.pais.message}</span></div>}



                    {/* Checkbox */}
                    {/* <div className="mb-4 flex items-center justify-between px-2">
                      <div className="flex items-center">
                        <Checkbox />
                        <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
                          Mantener sesión iniciada
                        </p>
                      </div>
                      <a
                        className="text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
                        href=" "
                      >
                        ¿Olvido su contraseña?
                      </a>
                    </div> */}

                    {isLoading &&
                      <div className="flex items-center justify-center">
                        <svg className="text-center animate-spin h-5 w-5 " xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                        </svg>
                      </div>
                    }

                    {error && !isLoading && <span className="text-red-500 block text-sm italic text-center mt-4">{error}</span>}
                    <button disabled={isLoading} type="submit" className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
                      Ingresar
                    </button>

                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>
        </form>
      </div>
    </div>

  );
}
