import { HttpGet, HttpPost, HttpPostImage, HttpPut, HttpDelete } from "./Http"


export const getField = async (field) => {
    try {
        const data = await HttpGet(`admin/configuracion/${field}`, false)
        return data;
    } catch (error) {
        return error
    }
}

export const setField = async (payload) => {
    try {
        const data = await HttpPost(`admin/configuracion`, JSON.stringify(payload))
        return data;
    } catch (error) {
        return error
    }
}