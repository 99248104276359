
// import { useAuth } from '@hooks/useAuth';
import { getEspecialidades, getEspecialidad } from "services/Especialidades";
import { useState } from "react";


const useSpecialties = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [response, setResponse] = useState([])
    const [error, setError] = useState(null)

    const fetchData = async (id = null, withReturn = false) => {
        setError(null)
        setIsLoading(true)

        try {
            let data;

            if (id) {
                data = await getEspecialidad(id)
                // setIsLoading(false)                
            } else {
                data = await getEspecialidades()
            }

            if (withReturn) {
                setResponse(id ? data : data)

                setIsLoading(false)

                return id ? data[0] : data
            }

            setResponse(id ? data : data)
            setIsLoading(false)
        } catch (error) {
            console.log(error)
            setError(error)
        } finally {
            setIsLoading(false)
        }

    }

    // const deleteUser = async (userId) => {
    //     await deleteUserData(userId)
    // }

    return { response, isLoading, error, fetchData }
}


export default useSpecialties