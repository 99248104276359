import { HttpGet, HttpPost, HttpPostImage, HttpPut, HttpDelete } from "./Http"


export const searchNotifications = async (payload) => {
    try {
        const data = await HttpPost(`admin/notificacion/buscar`, JSON.stringify(payload))
        return data;
    } catch (error) {
        return error
    }
}

export const changeStatusNotification = async (id, type) => {
    try {
        const data = await HttpGet(`admin/notificacion/${id}/cambiar/${type}`, false)
        return data;
    } catch (error) {
        return error
    }
}

export const updateNotification = async (id, payload) => {
    try {
        const data = await HttpPut(`admin/notificacion/${id}`, JSON.stringify(payload))
        return data;
    } catch (error) {
        return error
    }
}

export const custom = async (payload) => {
    try {
        const data = await HttpPost(`admin/notificacion/custom`, JSON.stringify(payload))
        return data;
    } catch (error) {
        return error
    }
}