import { Collapse, Popconfirm, Tag } from 'antd';
import Loader from 'components/loader';
import usePublications from "hooks/usePublications";
import useRequests from "hooks/useRequests";
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { storePublication } from 'services/Publications';
import { estadoPublicacion, routesNames } from "utils/Constants";
import { jornadas, states, tipoContratacion, turnos } from "utils/Tables";
import { formatDateFull, getNameFromObject } from "utils/Utils";
import PostulatedsTable from './components/PostulatedsTable';
import { getAllEspecialidades } from 'services/Especialidades';


const PUBLIC_URI = process.env.REACT_APP_PUBLIC_URI_IMAGES;

export default function Publication() {
    // let navigate = useNavigate()
    const { isLoading, response, fetchData } = usePublications()
    const { changeStatus } = useRequests()
    const [especialidades, setEspecialidades] = useState([])

    let { id } = useParams();

    useEffect(() => {
        fetchData(id)

        getEspecialidades()
    }, [])


    const getEspecialidades = async () => {
        const data = await getAllEspecialidades()

        // console.log(data)
        setEspecialidades(data)
    }

    if (isLoading) {
        return (
            <div className="mt-10 flex items-center justify-center">
                <Loader />
            </div>
        )
    }

    const changeStatusPublication = async (id, payload) => {

        const bodyPayload = {
            ...payload,
            type: 'P'
        }

        const data = await changeStatus(id, bodyPayload)
        window.location.reload()
        // navigate(routesNames.REQUESTS)
    }


    if (!isLoading && response) {
        return (
            <div className="mt-3 flex items-start flex-col min-h-screen gap-2">
                <div className='flex items-start justify-between gap-2 w-full'>
                    <div className='border rounded-lg w-full h-full p-4 bg-white'>
                        <div className=' border flex items-center gap-3 px-4 w-full py-3 rounded-lg'>
                            <div className='w-[80px] sm:w-[100px]'>
                                <img className="object-contain w-full h-full z-0" src={`${PUBLIC_URI}uploads/${response?.cuenta?.logo_url}`} />
                            </div>

                            <div className='flex lg:flex-row flex-col gap-1 items-center'>
                                <div className='flex items-start gap-3 flex-col'>
                                    <span className='text-xl sm:text-xl font-bold'>{response?.cuenta?.nombre}</span>
                                    {response?.cuenta?.slogan && <span className='font-semibold text-md' >{response.cuenta.slogan}</span>}
                                    {response?.cuenta?.resena && <span className='text-gray-500 text-xs text-start'>{response.cuenta.resena}</span>}
                                </div>
                                {response?.privada == 1 &&
                                    <span className='lg:text-lg text-sm font-bold bg-main text-black px-3 py-1 mt-2 rounded-lg text-center'>PUBLICACIÓN CONFIDENCIAL</span>
                                }
                            </div>
                        </div>

                        <div className='mt-4'>
                            <div className='flex sm:flex-row flex-col w-full gap-3 items-start'>
                                <div className='flex flex-col gap-3 w-full items-start'>
                                    <Link className='text-blue-600 text-sm' to={`${routesNames.PUBLICATION_EDIT}/${id}`}>Editar publicación</Link>
                                    <span className='my-2 text-xl font-bold'>{response?.nombre}</span>

                                    <div className='flex flex-col items-start'>
                                        <span className='text-md font-semibold mb-2 border-b block w-full'>Descripción general</span>
                                        <span id="publication-body" className="whitespace-pre-line text-sm text-start mt-1" dangerouslySetInnerHTML={{ __html: response.descripcion }}></span>
                                    </div>

                                    <div className='flex flex-col items-start gap-2 w-full mt-4'>
                                        <span className='text-md font-semibold mb-2 border-b block w-full'>Contratación y jornada</span>
                                        {/* <span className="text-sm text-start font-semibold">Tipo de contratación: <span className='font-normal'>{getNameFromObject(tipoContratacion, response.tipo_contratacion)}</span></span> */}
                                        <span className="text-sm text-start font-semibold">Turno: <span className='font-normal'>{getNameFromObject(turnos, response.turno)}</span></span>
                                        <span className="text-sm text-start font-semibold">Tipo de jornada: <span className='font-normal'>{getNameFromObject(jornadas, response.jornada)}</span></span>
                                        <span className="text-sm text-start font-semibold">Especialidad: <span className='font-normal'>{getNameFromObject(especialidades, response.especialidad_id, 'especialidad', true)}</span></span>

                                        <span className='text-md font-semibold mb-1 mt-4 border-b block w-full'>Ubicación</span>
                                        <span className="text-sm text-start font-semibold">Provincia: <span className='font-normal'> {getNameFromObject(states, response.ubicacion?.provincia)}</span></span>
                                        <span className="text-sm text-start font-semibold">Localidad: <span className='font-normal'>{response.ubicacion?.localidad}</span></span>
                                        {/* <span className="text-sm text-gray-500 text-start font-semibold">Zona: <span className='font-normal'>Norte GBA</span></span> */}
                                    </div>
                                </div>

                            </div>
                        </div>


                    </div>

                    <div className='border rounded-lg w-full h-full max-w-[500px] p-4 bg-white flex flex-col items-start'>
                        <Tag color={`${response?.estado?.estado?.color}`}>{response?.estado?.estado?.nombre}</Tag>

                        <div className='mt-4 flex flex-col gap-2 text-sm'>
                            <span className='text-lg mb-2 font-semibold'>Detalle operación</span>
                            {/* <span>Datos solicitante: {response.cuenta?.user?.email}</span> */}
                            {/* <span className='font-semibold'>Responsable:</span> */}
                            <div className='flex flex-col gap-0 mb-2'>
                                <span className=''>Cuenta: {response.cuenta?.nombre}</span>
                                <span className=''>Fecha: {formatDateFull(response?.created_at)}</span>
                            </div>

                            <span className='font-semibold'>Responsable:</span>
                            <div className='flex flex-col gap-0'>
                                {/* <span className=''>Nombre: {response.cuenta?.responsable_nombre}</span> */}
                                {/* <span className=''>Email: {response.cuenta?.responsable_email}</span> */}
                                <span className=''>Teléfono: {response.cuenta?.responsable_telefono}</span>
                                {/* <span className=''>DNI: {response.cuenta?.responsable_documento}</span> */}
                            </div>

                            {/* <span className='font-semibold mt-4'>Datos facturación:</span>
                            <div className='flex flex-col gap-0'>
                                <span className=''>{response.facturacion_sin_finalizar?.data?.razon_social}</span>
                                <span className=''>{response.facturacion_sin_finalizar?.data?.cuit}</span>
                                <span className=''>{getNameFromObject(condicionesIva, response.facturacion_sin_finalizar?.data?.condicion_iva)}</span>
                                <span className=''>{response.facturacion_sin_finalizar?.data?.calle} {response.facturacion_sin_finalizar?.data?.numero}, {response.facturacion_sin_finalizar?.data?.localidad}, {response.facturacion_sin_finalizar?.data?.provincia}</span>
                            </div> */}

                            {/* <span className='font-semibold mt-4'>Pack:</span>
                            <div className='flex flex-col gap-0'>
                                <span className=''>{response.facturacion_sin_finalizar?.pack?.nombre || "Republicar"} -  ${formatCurNumber(response.facturacion_sin_finalizar?.total)}</span>
                            </div> */}

                            {/* <span className='my-4'><span className='font-semibold'>Medio de pago:</span> {response.facturacion_sin_finalizar?.medio_pago?.nombre}</span> */}


                            {/* <span className='font-semibold text-2xl'>Total ${formatCurNumber(response.facturacion_sin_finalizar?.total)}</span> */}



                            <div className='flex flex-col gap-2 mt-2'>

                                {/* {response.facturacion_sin_finalizar?.pago_rechazado == 1 && <Tag color='red' className='text-center'>Pago rechazado</Tag>}
                                {response.facturacion_sin_finalizar?.pago_confirmado == 1 && <Tag color='green'>Pago confirmado</Tag>}
                                {response.facturacion_sin_finalizar?.facturado == 1 && <Tag color='blue'>Facturado</Tag>} */}

                                {/* {response.facturacion_sin_finalizar?.pago_confirmado == 0 && response.facturacion_sin_finalizar?.facturado == 0 &&
                                    <Popconfirm
                                        title="Confirmar y facturar pago"
                                        description="¿Está seguro que desea confirmar la recepción del pago y facturar el pedido?"
                                        onConfirm={async () => await changeStatusPublication(response.id, { pago_confirmado: 1, facturado: 1 })}
                                        okText="Si"
                                        cancelText="No"
                                        okButtonProps={
                                            { className: "bg-blue-500" }
                                        }
                                    >
                                        <button className='rounded-md px-4 py-1 text-sm bg-blue-600 text-white'>Confirmar y facturar</button>
                                    </Popconfirm>

                                } */}

                                {/* {response.facturacion_sin_finalizar?.pago_confirmado == 1 && response.facturacion_sin_finalizar?.facturado == 0 &&
                                    <Popconfirm
                                        title="Facturar"
                                        description="¿Está seguro que desea facturar el pedido?"
                                        onConfirm={async () => await changeStatusPublication(response.id, { facturado: 1 })}
                                        okText="Si"
                                        cancelText="No"
                                        okButtonProps={
                                            { className: "bg-blue-500" }
                                        }
                                    >
                                        <button className='rounded-md px-4 py-1 text-sm bg-blue-600 text-white'>Facturar pedido</button>
                                    </Popconfirm>

                                } */}

                                {/* {response.facturacion_sin_finalizar?.pago_confirmado == 0 &&
                                    <Popconfirm
                                        title="Confirmar pago"
                                        description="¿Está seguro que desea confirmar la recepción del pago?"
                                        onConfirm={async () => await changeStatusPublication(response.id, { pago_confirmado: 1 })}
                                        okText="Si"
                                        cancelText="No"
                                        okButtonProps={
                                            { className: "bg-blue-500" }
                                        }
                                    >
                                        <button className='rounded-md px-4 py-1 text-sm bg-aprobado text-white'>Confirmar pago</button>
                                    </Popconfirm>

                                } */}

                                {/* {response.facturacion_sin_finalizar?.pago_confirmado == 0 && response.facturacion_sin_finalizar?.pago_rechazado == 0 &&
                                    <Popconfirm
                                        title="Rechazar pago"
                                        description="¿Está seguro que desea rechazar el pago?"
                                        onConfirm={async () => await changeStatusPublication(response.id, { pago_rechazado: 1 })}
                                        okText="Si"
                                        cancelText="No"
                                        okButtonProps={
                                            { className: "bg-blue-500" }
                                        }
                                    >
                                        <button className='rounded-md px-4 py-1 text-sm bg-cancelado text-white'>Rechazar pago</button>
                                    </Popconfirm>
                                } */}

                                {/* {response.facturacion_sin_finalizar?.pago_confirmado == 0 &&
                                <Popconfirm
                                    title="Reembolsar pago"
                                    description="¿Está seguro que desea reembolsar el pago?"
                                    onConfirm={async () => await changeStatusPublication(response.id, { pago_reembolsado: 1 })}
                                    okText="Si"
                                    cancelText="No"
                                    okButtonProps={
                                        { className: "bg-blue-500" }
                                    }
                                >
                                    <button className='rounded-md px-4 py-1 text-sm bg-orange-500'>Reembolsar pago</button>
                                </Popconfirm>
                            } */}



                                {response.facturacion_sin_finalizar?.pago_confirmado == 1 && <span className='font-semibold text-base mt-4'>Publicación</span>}

                                {response.facturacion_sin_finalizar?.pago_confirmado == 1 && response.estado?.estado_id == estadoPublicacion.CANCELADO && <Tag color='red' className='text-center'>Publicación rechazada</Tag>}


                                {response.facturacion_sin_finalizar?.pago_confirmado == 1 && (response.estado?.estado_id == estadoPublicacion.PENDIENTE_APROBACION || response.estado?.estado_id == estadoPublicacion.CANCELADO) &&

                                    <Popconfirm
                                        title="blicación"
                                        description="¿Está seguro que desea aprobar la publicación?"
                                        // onConfirm={() => changeStatusPublication(response.id, estadoPublicacion.APROBADO)}
                                        onConfirm={async () => await changeStatusPublication(response.id, { estado: estadoPublicacion.APROBADO })}

                                        // onCancel={() => { }}
                                        okText="Si"
                                        cancelText="No"
                                        okButtonProps={
                                            { className: "bg-blue-500" }
                                        }
                                    >
                                        <button className='rounded-md px-4 py-1 text-sm bg-aprobado text-white'>{'Aprobar publicación'}</button>
                                    </Popconfirm>
                                }
                                {response.facturacion_sin_finalizar?.pago_confirmado == 1 && response.estado?.estado_id == estadoPublicacion.PENDIENTE_APROBACION &&
                                    <Popconfirm
                                        title="Rechazar publicación"
                                        description="¿Está seguro que desea rechazar la publicación?"
                                        onConfirm={async () => await changeStatusPublication(response.id, { estado: estadoPublicacion.CANCELADO })}
                                        okText="Si"
                                        cancelText="No"
                                        okButtonProps={
                                            { className: "bg-blue-500" }
                                        }
                                    >
                                        <button className='rounded-md px-4 py-1 text-sm bg-cancelado text-white'>Rechazar publicación</button>
                                    </Popconfirm>
                                }

                                {response.facturacion_sin_finalizar?.pago_confirmado == 1 && response.activa == 1 &&
                                    <Popconfirm
                                        title="Desactivar publicación"
                                        description="¿Está seguro que desea desactivar la publicación?"
                                        onConfirm={async () => {
                                            await storePublication({ activa: 0 }, response.id)
                                            window.location.reload()
                                        }}
                                        okText="Si"
                                        cancelText="No"
                                        okButtonProps={
                                            { className: "bg-blue-500" }
                                        }
                                    >
                                        <button className='rounded-md px-4 py-1 text-sm bg-cancelado text-white'>Desactivar publicación</button>
                                    </Popconfirm>
                                }

                                {response.facturacion_sin_finalizar?.pago_confirmado == 1 && response.activa == 0 && response.aprobada == 1 &&
                                    <Popconfirm
                                        title="Activar publicación"
                                        description="¿Está seguro que desea activar la publicación?"
                                        onConfirm={async () => {
                                            await storePublication({ activa: 1 }, response.id)
                                            window.location.reload()
                                        }}
                                        okText="Si"
                                        cancelText="No"
                                        okButtonProps={
                                            { className: "bg-blue-500" }
                                        }
                                    >
                                        <button className='rounded-md px-4 py-1 text-sm bg-aprobado text-white'>Activar publicación</button>
                                    </Popconfirm>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className=' mt-5 w-full mb-3'>
                    <Collapse
                        items={[{
                            key: '1',
                            label: 'Postulaciones',
                            children: <PostulatedsTable id={id} />
                        }]}
                    // defaultActiveKey={['1']} 
                    />
                </div>
            </div >
        )
    }
}

