import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import { AuthProvider } from './hooks/useAuth';
import { ConfigProvider } from 'antd';

import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <AuthProvider>
      <ConfigProvider
        theme={{

          components: {
            Switch: {
              // handleBg: "#FFD625",
              // handleShadow: "#FFD625"
            }
          }
        }}
      >
        <App />
      </ConfigProvider>
    </AuthProvider>
  </BrowserRouter>
);
