
import { useEffect, useState } from "react";
import { getBloques, setBloques } from "services/Bloques"

const useBloques = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [response, setResponse] = useState([])
    const [error, setError] = useState(null)

    useEffect(() => {
        fetchAll()
    }, [])

    const fetchAll = async () => {
        setIsLoading(true)
        const data = await getBloques()

        setResponse(data)
        setIsLoading(false)
    }


    const store = async (payload, onFinish) => {
        setIsLoading(true)

        const data = await setBloques({ items: payload })

        setIsLoading(false)
        onFinish(data)
    }





    return { response, isLoading, error, store, fetchAll }
}


export default useBloques