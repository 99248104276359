import { APIURI } from "config/config"
import { getItem } from '../storage/UserAsyncStorage';
import { getCountry } from "config/config";

export const HttpGet = async (uri, destructure = false) => {
    const userData = await getItem()
    const token = JSON.parse(userData)?.token

    try {
        const data = await fetch(`${APIURI}${uri}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                Country: getCountry()
            },
        });

        const response = await data.json()

        return response.data;
    } catch (error) {
        console.log(error)
        return errorResponse(error.response);
    }
};

export const HttpDelete = async (uri, destructure = false) => {
    const userData = await getItem()
    const token = JSON.parse(userData)?.token

    try {
        const data = await fetch(`${APIURI}${uri}`, {
            headers: {
                Authorization: `Bearer ${token}`,
                Country: getCountry()
            },
            method: 'DELETE'
        });

        const response = await data.json()

        return response.data;
    } catch (error) {
        console.log(error)
        return errorResponse(error.response);
    }
};

export const HttpPost = async (uri, payload = "", withToken = true, optionalHeaders = {}) => {
    const userData = await getItem()
    const token = JSON.parse(userData)?.token

    try {
        const headers = withToken
            ? {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                Country: getCountry(),
                ...optionalHeaders
            }
            : {
                "Content-Type": "application/json",
                "Accept": "application/json",
                Country: getCountry(),
                ...optionalHeaders
            };

        const data = await fetch(`${APIURI}${uri}`, {
            method: "POST",
            body: payload,
            headers: headers
        });

        const response = await data.json()

        return response;
    } catch (error) {
        return errorResponse(error);
    }
};

export const HttpPostImage = async (uri, payload = "", withToken = true, optionalHeaders = {}) => {
    const userData = await getItem()
    const token = JSON.parse(userData)?.token

    try {
        const headers = withToken
            ? {

                Authorization: `Bearer ${token}`,
                Country: getCountry()
            }
            : {
                Country: getCountry()
            };

        const data = await fetch(`${APIURI}${uri}`, {
            method: "POST",
            body: payload,
            headers: headers
        });

        const response = await data.json()

        return response;
    } catch (error) {
        return errorResponse(error);
    }
};

export const HttpPut = async (uri, payload = "") => {
    const userData = await getItem()
    const token = JSON.parse(userData)?.token

    try {
        const data = await fetch(`${APIURI}${uri}`, {
            method: "PUT",
            body: payload,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
                Country: getCountry()
            },
        })

        const response = await data.json()
        return response;

    } catch (error) {
        return errorResponse(error.response);
    }
};

// export const HttpPatch = async (uri, payload = "", desustructed = false) => {
//     try {
//         const { data } = await axios.patch(`${APIURI}${uri}`, payload, {
//             headers: {
//                 "Content-Type": "application/json",
//                 Authorization: `Bearer ${userData.token}`,
//             },
//         });
//         if (desustructed) {
//             const response = {
//                 ...data.data,
//                 ...{ status_code: data.status_code },
//                 ...{ message: data.message },
//             };
//             return response;
//         } else {
//             return data;
//         }
//     } catch (error) {
//         return errorResponse(error.response);
//     }
// };

// export const HttpDelete = async (uri, desustructed = false) => {
//     try {
//         const { data } = await axios.delete(`${APIURI}${uri}`, {
//             headers: {
//                 "Content-Type": "application/json",
//                 Authorization: `Bearer ${userData.token}`,
//             },
//         });

//         if (desustructed) {
//             const response = {
//                 ...data.data,
//                 ...{ status_code: data.status_code },
//                 ...{ message: data.message },
//             };
//             return response;
//         } else {
//             return data;
//         }
//     } catch (error) {
//         return errorResponse(error.response);
//     }
// };

const errorResponse = (message) => {
    return {
        data: [],
        status: 404,
        message: `Error en la conexión con el servidor : ${message}`,
        error: true,
    };
};
