
// import { useAuth } from '@hooks/useAuth';
import { getStats } from "services/Stats";
import { useState } from "react";


const useStats = (uri) => {
    const [isLoading, setIsLoading] = useState(false)
    const [response, setResponse] = useState([])
    const [error, setError] = useState(null)

    const fetchData = async (withReturn = true) => {
        setError(null)
        setIsLoading(true)

        try {

            const data = await getStats(uri)

            if (withReturn) {
                // setResponse(data)
                setIsLoading(false)
                return data
            }

            setResponse(data)
            setIsLoading(false)
        } catch (error) {
            console.log(error)
            setError(error)
        } finally {
            setIsLoading(false)
        }

    }


    return { response, isLoading, error, fetchData }
}


export default useStats