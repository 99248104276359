export default function SelectUseForm({ register, name, errors, placeholder, label, options = [], className = "", classNameSelect = "", rules = {}, disabled = false }) {
    return (
        <div className={className}>
            {label && <label className="text-start block font-semibold mb-2 mt-5">{label} {rules.required && <span className="text-red-500">*</span>}</label>}
            <select
                disabled={disabled}
                {...register(name, rules)}
                className={classNameSelect + " disabled:opacity-25 text-sm p-2 rounded-md w-full mb-2 bg-gray-50 py-4 px-4" + (errors[`${name}`] && " border-red-500")}
                placeholder={placeholder}
            >
                <option value="">Seleccionar</option>
                {options.map((item, key) => (
                    <option key={key} value={item.value}>{item.name}</option>
                ))}
            </select>
            {errors[`${name}`] && <div className="text-start"><span className="text-red-500 text-sm italic text-start">{errors[`${name}`].message}</span></div>}

        </div>
    )
}
