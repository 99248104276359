import { Table } from "antd";
import { SITE_URL } from "config/config";
import usePublications from "hooks/usePublications";
import { FaFilePdf } from "react-icons/fa";
import { useEffect, useState } from "react";
import { getFullDay, getFullMonth } from "utils/Utils";
import { getCurriculumPDF, getCurriculumPDFPostulateds } from "services/Usuarios"
import useNotification from 'hooks/useNotification';
import { BASE_API } from "config/config"
import Loader from "components/loader";



export default function PostulatedsTable({ id }) {
    const { isLoading, response, getPostulateds } = usePublications()
    const { contextHolder, openNotification } = useNotification()
    const [isLoadingPdf, setIsLoadingPdf] = useState(false)

    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',

        },
        {
            title: 'Nombre',
            dataIndex: 'cuenta_id',
            key: 'nombre',
            render: (text, record) => <span>{record.postulante?.nombre} {record.postulante?.apellido}</span>,
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            render: (text, record) => <span>{record.postulante?.email}</span>,
        },
        {
            title: 'Fecha',
            dataIndex: 'created_at',
            render: (text) => {
                const date = new Date(text);
                return `${getFullDay(date, true)}/${getFullMonth(date)}/${date.getFullYear()}`
            }
        },
        {
            title: 'CV',
            dataIndex: 'created_at',
            render: (text, record) => {
                return <div className="flex items-center gap-1">
                    <a target='_blank' rel='noopener noreferrer' href={`${SITE_URL}cv/${record.postulante?.uuid}`} className='bg-main text-xs text-white px-2 rounded-md py-1'>Ver curriculum</a>
                    <a onClick={() => fetchPdf(record?.user_id)} className='bg-red-500 text-xs text-white px-2 rounded-md py-1 flex items-center'><FaFilePdf className='text-white' /> Descargar</a>
                </div>

            }
        },


    ];

    const fetchPdf = async (userId) => {
        const data = await getCurriculumPDF(userId)

        if (data?.uuid) {
            window.open(`${BASE_API}cv/${data.uuid}.pdf`, "_blank", "noreferrer");
        } else {
            openNotification('El curriculum se encuentra incompleto', 'success', 'Atención!')
        }
    }

    const fetchPdfAll = async () => {
        setIsLoadingPdf(true)
        const data = await getCurriculumPDFPostulateds(id)

        if (data?.file) {
            window.open(`${BASE_API}${data.file}`, "_blank", "noreferrer");
        } else {
            openNotification('Ocurrió un error al obtener todos los curriculums.', 'success', 'Atención!')
        }
        setIsLoadingPdf(false)
    }

    useEffect(() => {
        if (id) {
            getPostulateds(id)
        }
    }, [id])

    return (
        <div className="flex flex-col items-end gap-2">
            {contextHolder}

            <button disabled={isLoadingPdf} onClick={() => fetchPdfAll()} className='bg-red-500 disabled:bg-red-400 text-white flex gap-2 items-center mb-2 px-3 py-1 rounded-lg'>{isLoadingPdf ? <><Loader small={true} /> Descargando </> : <><FaFilePdf className='text-white' />  Descargar CV de todos los postulantes </>}</button>
            <Table
                className="w-full"
                loading={isLoading}
                columns={columns}
                dataSource={response[0]?.postulaciones}
                rowKey={(record) => record.id}
                pagination={{
                    showSizeChanger: false,

                }}
                size="small"
                locale={{
                    emptyText: "No hay postulaciones"
                }}
            />
        </div>
    )
}
