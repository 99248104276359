import PieChart from "components/charts/PieChart";
// import { pieChartData, pieChartOptions } from "variables/charts";
import Card from "components/card";
import useStats from "hooks/useStats";
import { useState, useEffect } from "react";
import Loader from "components/loader";

const PieChartCard = () => {

  const { isLoading, fetchData } = useStats('usuarios')
  const [usuarios, setUsuarios] = useState({
    data: [],
    options: []
  })

  const getUsers = async () => {
    const data = await fetchData()
    const labels = []

    const tmp = data.map(user => {
      labels.push(user.type == 'I' ? 'Instituciones' : 'Postulantes')
      return parseFloat(user.count)
    });

    const pieChartOptions = {
      labels: labels,
      colors: ["#FFD625", "#000000"],
      chart: {
        width: "50px",
      },
      states: {
        hover: {
          filter: {
            type: "none",
          },
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      hover: { mode: null },
      plotOptions: {
        donut: {
          expandOnClick: false,
          donut: {
            labels: {
              show: false,
            },
          },
        },
      },
      fill: {
        colors: ["#FFD625", "#000000"],
      },
      tooltip: {
        enabled: true,
        theme: "dark",
        style: {
          fontSize: "12px",
          fontFamily: undefined,
          backgroundColor: "#000000"
        },
      },
    };

    setUsuarios({ options: pieChartOptions, data: tmp })
  }

  useEffect(() => {
    getUsers()

  }, [])

  if (isLoading) {
    return (<Loader />)
  }

  return (
    <Card extra="rounded-[20px] p-3">
      <div className="flex flex-row justify-between px-3 pt-2">
        <div>
          <h4 className="text-lg font-bold text-navy-700 dark:text-white">
            Usuarios
          </h4>
        </div>
      </div>

      <div className="mb-auto flex h-[220px] w-full items-center justify-center">
        {usuarios?.data?.length > 0 && <PieChart options={usuarios?.options} series={usuarios?.data} />}
      </div>
      <div className="flex flex-row !justify-between rounded-2xl px-6 py-3 shadow-2xl shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
        <div className="flex flex-col items-center justify-center">
          <div className="flex items-center justify-center">
            <div className="h-2 w-2 rounded-full bg-brand-500" />
            <p className="ml-1 text-sm font-normal text-gray-600">Instituciones</p>
          </div>
          <p className="mt-px text-xl font-bold text-navy-700  dark:text-white">
            {usuarios?.data[0]}%
          </p>
        </div>

        <div className="h-11 w-px bg-gray-300 dark:bg-white/10" />

        <div className="flex flex-col items-center justify-center">
          <div className="flex items-center justify-center">
            <div className="h-2 w-2 rounded-full bg-[#000000]" />
            <p className="ml-1 text-sm font-normal text-gray-600">Postulantes</p>
          </div>
          <p className="mt-px text-xl font-bold text-navy-700 dark:text-white">
            {usuarios?.data[1]}%
          </p>
        </div>
      </div>
    </Card>
  );
};

export default PieChartCard;
