import React from 'react'
import UsersTable from './components/UsersTable'

export default function index() {
    return (
        <div className="mt-1 flex flex-col items-start">

            <UsersTable />
        </div>
    )
}
